import React, { useEffect, useState } from 'react';
import NavBar from '../../Navigation/NavBar';
import Footer from '../LandingPage/Footer';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from 'rsuite/esm/useMediaQuery/useMediaQuery';
import axios from 'axios';
import { Link } from 'react-router-dom';
import zIndex from '@mui/material/styles/zIndex';

function Blogs() {
  const isXs = useMediaQuery('(min-width:600px) and (max-width:3000px)');
  const [blogBox, setBlogBox] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  async function blogfunction() {
    const token = {
      token: 'abcd123'
    };

    try {
      const response = await axios.post(`https://app.kanhahometutions.com/api/v1/blogPosts`, token);
      setBlogBox(response.data.data);
    } catch (error) {
      setError("Failed to load blogs. Please try again.");
      console.error("Error fetching blog posts:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    blogfunction();
  }, []);

  const generateSlug = (title) => {
    return (title?.toLowerCase() ?? '')
        .replace(/[^a-z0-9]+/g, '-') 
        .replace(/^-+|-+$/g, ''); 
  };

  return (
    <>
      <NavBar />

      <div style={{ backgroundColor: '#CB8B65', display: 'flex', alignItems: 'center', paddingLeft: isXs ? '6rem' : '1rem', gap: '10px', height: '50px' }}>
        <div><a style={{ color: 'white', textDecoration: 'none', fontSize: '1rem' }} href="/">Home</a></div>
        <ArrowForwardIosIcon style={{ fontSize: '14px', color: 'white' }} />
        <div style={{ fontSize: '1rem', color: 'white' }}>Blogs</div>
      </div>

      <div style={{fontSize:'28px' , textAlign:'center', marginTop:'40px', fontWeight:'bold'}}>The Learning Hub <br /> Your Go-To Guide for Academic Success</div>


      <div style={{ position: 'relative', padding: '3rem 2rem 10rem 2rem', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', justifyItems: 'center', rowGap: '50px', gridAutoRows: 'auto', backgroundColor:'#ffffff', height:'auto' }}>
        {loading && <div>Loading blogs...</div>}
        {error && <div>{error}</div>}

        {blogBox.slice(0, 50).map((item, index) => (
          <div key={index} style={{ height: '450px', width: '358px', borderRadius: '20px', display: 'flex', flexDirection: 'column', gap: "10px", backgroundColor:'#F5F5F5' }}> 
            <div style={{ height: '250px', width: '100%' }}>
              <img
                style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '20px', border:'1px solid lightgrey' }}
                src={item.postFile}
                alt={`blog-image-${index}`}
              />
            </div>
            <div style={{ display: 'flex', padding: '10px 20px' }}>
              <div style={{ color: '#CB8B65', textTransform: 'uppercase', fontWeight: 'bold' }}>{item.postCategory}</div>&nbsp; /
              <div>&nbsp;{item.postDate}</div>
            </div>
            <div style={{ fontSize: '24px', lineHeight: '2rem', padding: '0px 20px', letterSpacing: '0.2px', wordSpacing: '-2px', fontWeight: '500', height: '96px' }}>
              <Link style={{color:'black', textDecoration:'none'}} to={`/blog/${item.postTitle.toLowerCase().replaceAll(
                              " ",
                              "-"
                            )}`}
                            state={item}
                          >
<div style={{ height: '80px' }}>
{item.postTitle.length > 50 ? item.postTitle.substring(0, 56) + '...' : item.postTitle}
</div><div style={{ padding: '0px 0px', color: '#CB8B65', fontWeight: '500', fontSize:'15px'}}>Read More...</div>
                          </Link>
            </div>

            {/* <div dangerouslySetInnerHTML={{ __html: item.postDescription }}></div> */}
          </div>
        ))}
      </div>

    <Footer />
    </>
  );
}

export default Blogs;
