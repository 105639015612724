import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import search from "../../assets/search.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';  
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';  
import { Dialog, TextField } from "@mui/material";
import axios from "axios";
import { boardListNEW, boardListV2 } from "../features/admin/accountPostSlice";
import { useDispatch } from "react-redux";
import axiosInstanceSM from "../../Utils/axiosSm";
import { DialogActions, DialogContent, DialogTitle,  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
    '& .MuiSwitch-thumb': {
      color: theme.palette.action.disabled,
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
}));

const columns = [
  { id: "id", label: "ID" },
  { id: "BoardName", label: "Board Name", minWidth: "360px" },
  // { id: "Status", label: "Status", minWidth: "200px" },
  { id: "Action", label: "Action", minWidth: "200px" },
];

function BoardAndCourses() {

  
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [boardName , setBoardName] = useState('')
  const [openNew , setOpenNew] = useState()
  const [data, setData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [openDelete, setOpenDelete] = useState('');
  const [deleteId, setDeleteId] = useState(null);
 
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClose = () => {
    setOpenNew(false);  
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleBoardSubmit = async (e) => { 
    e.preventDefault();
    const formdata = new FormData();
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`;
    formdata.append("boardName", boardName);
    formdata.append("token", token);
    setIsDialogOpen(false);


    try {
      const response = await axiosInstanceSM.post("api/sm/v1/board", formdata);
      if (response.data.success == true) {  
        toast.success(response.data.message)
        fetchBoardData()
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      console.error("Error submitting board:", error);
    }
  };

  const fetchBoardData = async () => {
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`; 
    const payload = {token: token};
    try {
      const response = await axiosInstanceSM.post("api/sm/v1/boards" ,payload);
      setData(response.data.data) 
  } catch (error) {
    if (error.response) {
      toast.error(error.response.data.message || 'Something went wrong!');
    }
    }
  };

  useEffect(() => {
    fetchBoardData();
  }, []); 

  const handleEditNew = (id) => {
    const item = data.find((i) => i.boardId === id);
    setBoardName(item.boradName); 
    setEditId(id);
    setOpenNew(true); 
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const formdataNew = new FormData();
    formdataNew.append("token", "abcd123");
    formdataNew.append("_method", "PUT");
    formdataNew.append("boardName", boardName);
    try {
      const response = await axiosInstanceSM.post(`api/sm/v1/board/${editId}`, formdataNew);
      setData(prevData =>
        prevData.map(item => {
          if (item.boardId === editId) {
            item.boardName = data.boardName; 
          }
          return item; 
        })
      );
      setOpenNew(false); 
if(response.data.success == true){
  toast.success(response.data.message)
  fetchBoardData()
}
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message || 'Something went wrong!');
      } }
  };

  const deleteRow = async () => {
    if (!deleteId) return; 
    setOpenDelete(false);

    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`;
    const payload = {
      token: token,
      _method: 'DELETE',
    };
  
    try {
      const response = await axiosInstanceSM.post(`api/sm/v1/board/${deleteId}`, payload);
      if (response.data.success == true) {
        setData((prevData) => prevData.filter((row) => row.id !== deleteId));
        toast.success(response.data.message)
        fetchBoardData()
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message || 'Something went wrong!');
      }
    }
  };
  

  const boardList = () => {
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`;
    const payload = {
      token: token,
    };
    dispatch(boardListV2(payload));
  };

  useEffect(() => {
    boardList();
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "4rem",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          marginTop: "0rem",
          display: "flex",
          alignItems: "center",
          padding: "1rem",
          justifyContent: "space-between",
          boxShadow: "0px 4px 4px 2px rgb(231, 231, 231)",
        }}
      >
        <span style={{ color: "#CB8B65", fontSize: "1.2rem", fontWeight: "600" }}>
          Boards / Courses
        </span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputGroup>
            <Form.Control
              placeholder="Search...."
              aria-label="Recipient's userBoardName"
              aria-describedby="basic-addon2"
              value={searchTerm}
            />
            <Button style={{ backgroundColor: "#CB8B65", border: 0 }}>
              <img src={search} alt="" />
            </Button>
          </InputGroup>
        </div>
      </div>

      <div style={{ marginLeft: "2rem", paddingTop: "30px", paddingBottom: "10px" }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ fontSize: "1.5rem" }}>Boards / Courses List</div>
          <div 
            onClick={openDialog} 
            style={{
              marginRight: '2rem', 
              color: 'white', 
              borderRadius: '5px', 
              backgroundColor: '#CB8B65', 
              padding: '6px 10px', 
              cursor: 'pointer'
            }}
          >
            Add New
          </div>
        </div>
      </div>

      {isDialogOpen && (
        <div
          id="backdrop"
          style={{
            position: "fixed",
            top: "0", left: "0", right: "0", bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "999",
            display: "flex", justifyContent: "center", alignItems: "center",
          }}
        >
          <div 
            style={{
              position: "relative", backgroundColor: "white", padding: "20px", borderRadius: "8px", width: "400px",
            }}
          >
            <button 
              onClick={closeDialog} 
              style={{
                position: "absolute", top: "10px", right: "10px", background: "none", border: "none", fontSize: "20px", cursor: "pointer"
              }}
            >
              &times; 
            </button>
            <h3 style={{marginBottom:'15px'}}>Add New Board/Course</h3>
            <form onSubmit={handleBoardSubmit}>
              <div>
                <TextField
                  label="Board/Courses Name"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => setBoardName(e.target.value)}
                  placeholder="Enter Board / Courses Name"
                  style={{ marginBottom: "10px" }}
                />
              </div>
              <DialogActions>
              <button type="submit" style={{ backgroundColor: "#CB8B65", color: "white", padding: "10px 15px", borderRadius: "5px" }}>
                Submit
              </button>
              </DialogActions>
            </form>
          </div>
        </div>
      )}

 <Dialog open={openNew} onClose={handleClose}>
        <DialogTitle>Edit Board / Course </DialogTitle>
        <DialogContent>
                 <TextField
                  label="Board/Courses Name"
                  variant="outlined"
                  fullWidth
                  value={boardName}
                  onChange={(e) => setBoardName(e.target.value)}
                  placeholder="Enter Board / Courses Name"
                  style={{ marginBottom: "10px",  marginTop:'10px'}}
                />
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} style={{ backgroundColor: "#CB8B65", color: "white", padding: "10px 15px", borderRadius: "5px" }}>
            Cancel
          </button>
          <button onClick={handleEditSubmit} style={{ backgroundColor: "#CB8B65", color: "white", padding: "10px 15px", borderRadius: "5px" }}>
            Save
          </button>
        </DialogActions>
      </Dialog>


      <Dialog  open={openDelete} onClose={handleCloseDelete}> 
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: ' 40px 30px'  }}>
    <CloseIcon style={{ color: 'red', fontSize: '60px', padding:'10px', marginBottom: '20px', borderRadius:'50%', border:'2px solid red'   }} />
    <div style={{ fontSize: '30px', textAlign: 'center', marginBottom: '20px' }}>
      Are you sure ?
    </div>
    <div style={{textAlign: 'center',width:'320px' , marginBottom: '20px' }}>Do you really want to delete this item? This Process cannot be undone. </div>
    <DialogActions style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <button 
        onClick={handleCloseDelete} 
        style={{ backgroundColor: "#9c9d9e", color: "white", padding: "7px 15px", borderRadius: "5px", marginRight: '10px', width:'100px', border:'0px' }}
      >
        Cancel
      </button>
      <button 
        onClick={deleteRow} 
        style={{ backgroundColor: "red", color: "white", padding: "7px 15px", borderRadius: "5px", width:'100px', border:"0px"}}
      >
        Yes
      </button>
    </DialogActions>
  </div>
</Dialog>


      <Paper sx={{ width: "95%", overflow: "hidden", scrollBehavior: "smooth", marginLeft: "2rem" }}>
        <TableContainer sx={{ maxHeight: 360 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#CB8B65",
                      fontWeight: "500",
                      color: "#ffffff",
                      fontSize: "1.1rem",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
  {Array.isArray(data) && data.map((item) => (
    <TableRow hover key={item.boardId}>
      <TableCell>{item.boardId}</TableCell>
      <TableCell>{item.boradName}</TableCell>
      {/* <TableCell>
        <CustomSwitch
          checked={item.accountStatus}  
          color="warning"
        />
      </TableCell> */}
      <TableCell>
        <IconButton onClick={() => handleEditNew(item.boardId)} color="warning">
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => {setDeleteId(item.boardId); setOpenDelete(true); }}color="warning">
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ))}
</TableBody>
          </Table>
        </TableContainer>


        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          page={page}
        /> */}
      </Paper>
    </>
  );
}

export default BoardAndCourses;
