import React, { useState } from 'react';
import ReactRte from 'react-rte';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RichTextEditor from 'react-rte';
import axiosInstanceSM from '../../../Utils/axiosSm';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";



function AddPost() {
    const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());
    const [category, setCategory] = useState('');
    const [authorName, setAuthorName] = useState('');
    const [date, setDate] = useState('');
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const navigate = useNavigate();

    const [imageName, setImageName] = useState('');

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileSizeInKB = file.size / 1024; 
            if (fileSizeInKB > 100) {
                toast.error("File size must be less than 100 KB");
            } else {
                setImage(file);
                setImageName(file.name);
            }
        }
    };

    const handleChange = (newValue) => {
        setEditorValue(newValue);
    };

 const handleSubmit = async (e)=> {
    e.preventDefault(e)

    const htmlValue = editorValue.toString('html');
    const formData = new FormData();
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `abcd1234${text}`;
    formData.append('postTitle', title);
    formData.append('postCategory', category);
    formData.append('postAuthor', authorName);
    formData.append('postDate', date);
    formData.append('postDescription', htmlValue);
    formData.append('postFile', image);
    formData.append('token', token);

    try {
const response = await axiosInstanceSM.post("/api/v1/blogPost" ,formData );
if (response.data.data = true){
    toast.success(response.data.message)
    navigate('/dashboard/blogs/all-post');
    setTitle('');
    setCategory('');
    setAuthorName('');
    setDate('');
    setEditorValue('');
    setImage('');
}
else{
    toast.error(response.data.data.message);
}
    }
    catch(error){
        if (error.response) {
            toast.error(error.response.data.message || 'Something went wrong!');
        }
    }

 }

    return (
        <form onSubmit={handleSubmit}>
            <div
                style={{
                    width: "100%",
                    height: "4rem",
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    marginTop: "0rem",
                    display: "flex",
                    alignItems: "center",
                    padding: "1rem",
                    justifyContent: "space-between",
                    boxShadow: "0px 4px 4px 2px rgb(231, 231, 231)",
                }}
            >
                <span style={{ color: "#CB8B65", fontSize: "1.2rem", fontWeight: "600" }}>
                    Add Post
                </span>
                <button type='submit' style={{ backgroundColor: '#CB8B65', padding: '10px 15px', color: 'white', borderRadius: '10px' }}>Publish</button>
            </div>

            <div style={{ padding: '1rem 1.5rem 0.5rem 1.5rem', fontSize: '20px' }}>Add New Post</div>
            <hr style={{ margin: '0px', border: '1px solid' }} />

            <div style={{ padding: '1rem 1.5rem', display: 'flex', flexDirection: "column", gap: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <label htmlFor="file" style={{ cursor: 'pointer', height: '100px', width: '100%', border: '1px dashed #000', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div><CloudUploadIcon /></div>
                            <div style={{ fontSize: '20px' }}>
                                {imageName ? imageName : 'Upload a high quality Image'}
                            </div>
                        </div>
                    </label>
                    <input accept="image/*"  required id="file" type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                </div>

                <div style={{ display: 'flex', gap: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                        <label style={{ fontSize: '16px', marginBottom: '6px', marginLeft: '10px', fontWeight: '500' }} htmlFor="category">Category</label>
                        <select required
                            onChange={(e) => setCategory(e.target.value)}
                            id="category"
                            style={{ height: '40px', borderRadius: '10px', border: '1px solid', paddingLeft: '10px' }}>
                            <option value=""></option>
                            <option value="For Students">For Students</option>
                            <option value="For Parents">For Parents</option>
                            <option value="For Teachers">For Teachers</option>
                        </select>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                        <label style={{ fontSize: '16px', marginBottom: '6px', marginLeft: '10px', fontWeight: '500' }} htmlFor="authorName">Author Name</label>
                        <input required onChange={(e) => setAuthorName(e.target.value)}
                            id="authorName"
                            style={{ height: '40px', borderRadius: '10px', border: '1px solid', paddingLeft: '10px' }}
                            type="text"
                            placeholder="Enter Author Name"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: "column", width: '33%' }}>
                        <label style={{ fontSize: '16px', marginBottom: '6px', marginLeft: '10px', fontWeight: '500' }} htmlFor="date">Select Date</label>
                        <input required onChange={(e) => setDate(e.target.value)}
                            id="date"
                            style={{ height: '40px', borderRadius: '10px', border: '1px solid', paddingLeft: '10px' }}
                            type="date"
                            placeholder="Select Date"
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label style={{ fontSize: '16px', marginBottom: '6px', marginLeft: '10px', fontWeight: '500' }} htmlFor="title">Title</label>
                    <input required onChange={(e) => setTitle(e.target.value)} style={{ height: '40px', borderRadius: '10px', border: '1px solid', paddingLeft: '10px' }} type="text" placeholder="Enter Title" />
                </div>

                <div>
                    <ReactRte required
                        value={editorValue}
                        onChange={handleChange}
                        editorStyle={{
                            overflowY: 'auto',
                            maxHeight: '250px',
                            height: '100%',
                        }}
                    />
                </div>
            </div>
        </form>
    );
}

export default AddPost;
