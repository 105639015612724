import React, { useEffect, useState } from 'react';
import InputGroup from "react-bootstrap/InputGroup";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import search from "../../../assets/search.png";
import { DialogActions, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstanceSM from '../../../Utils/axiosSm';
import ReactRte from 'react-rte';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RichTextEditor from 'react-rte';
import { toast } from "react-toastify";


function Allpost() {
  const columns = [
    { id: "id", label: "ID" },
    { id: "Title", label: "Title", minWidth: "120px" },
    { id: "Author", label: "Author", minWidth: "100px" },
    { id: "Categories", label: "Categories", minWidth: "120px" },
    { id: "Date", label: "Date", minWidth: "100px" },
    { id: "Action", label: "Action", minWidth: "120px" },
  ];

  const [data, setData] = useState([])
  const [open, setOpen] = useState('');
  const [openNew, setOpenNew] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());
  const [category, setCategory] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [date, setDate] = useState('');
  const [title, setTitle] = useState('');
  const [editId, setEditId] = useState(null);
  const [image, setImage] = useState(null);


  const handleChange = (newValue) => {
    setEditorValue(newValue);
  };


  const handleOpenDelete = () => { setOpen(true) };
  const handleCloseDelete = () => { setOpen(false) };
  const handleClose = () => { setOpenNew(false) };
  const handleEdit = () => { setOpenNew(true) };



  const [imageName, setImageName] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > 100) {
        toast.error("File size must be less than 100 KB");
      } else {
        setImage(file);
        setImageName(file.name);
      }
    }
  };



  async function fetchblogs() {
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `abcd1234${text}`;
    const payload = { token: token };
    try {
      const response = await axiosInstanceSM.post("api/v1/blogPosts", payload);
      setData(response.data.data)
    } catch (error) {
      if (error.response.data) {

      }
    }
  };

  async function fetchEdit(id) {
    setOpenNew(true)
    console.log(id)
    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `abcd1234${text}`;
    const payload = { token: token };
    try {
      const response = await axiosInstanceSM.post(`api/v1/blogPost/${id}`, payload);

      setTitle(response.data.data.postTitle)
      setAuthorName(response.data.data.postAuthor);
      setCategory(response.data.data.postCategory);
      setDate(response.data.data.postDate, "post Date");
      setImage(response.data.data.postFile);
      setEditId(id);
      setEditorValue(RichTextEditor.createValueFromString(response.data.data.postDescription, 'html'));

    } catch (error) {
      if (error.response.data) {

      }
    }
  };


  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const id = editId;
    const formdataNew = new FormData();
    formdataNew.append('postTitle', title);
    formdataNew.append('postCategory', category);
    formdataNew.append('postAuthor', authorName);
    formdataNew.append('postDate', date);
    formdataNew.append('postDescription', editorValue.toString('html'));
    formdataNew.append('postFile', image);
    formdataNew.append("token", "abcd123");
    formdataNew.append("_method", "PUT");
    try {
      const response = await axiosInstanceSM.post(`api/v1/blogPost/${id}`, formdataNew);
      if (response.data.success == true) {
        toast.success(response.data.message)
        setOpenNew(false)

      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message || 'Something went wrong!');
      }
    }
  };


  useEffect(() => {
    fetchblogs();
  }, []);

  const deletePost = async () => {
    if (!deleteId) return;

    const d = new Date();
    let text = d.toLocaleDateString();
    let token = `vkSoft1234${text}`;

    const payload = {
      token: token,
      _method: 'DELETE',
    };

    try {
      const response = await axiosInstanceSM.delete(`api/v1/blogPost/${deleteId}`, { data: payload });
      if (response.data.success == true) {
        toast.success(response.data.message);
        setOpen(false);

      } else {
        toast.error(response.data.message)
      }
    }
    catch (err) {
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "4rem",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          marginTop: "0rem",
          display: "flex",
          alignItems: "center",
          padding: "1rem",
          justifyContent: "space-between",
          boxShadow: "0px 4px 4px 2px rgb(231, 231, 231)",
        }}
      >
        <span style={{ color: "#CB8B65", fontSize: "1.2rem", fontWeight: "600" }}>
          All Post
        </span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputGroup>
            <Form.Control
              placeholder="Search...."
              aria-label="Recipient's userBoardName"
              aria-describedby="basic-addon2"
            />
            <Button style={{ backgroundColor: "#CB8B65", border: 0 }}>
              <img src={search} alt="" />
            </Button>
          </InputGroup>
        </div>
      </div>

      <Dialog open={open} onClose={handleCloseDelete}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: ' 40px 30px' }}>
          <CloseIcon style={{ color: 'red', fontSize: '60px', padding: '10px', marginBottom: '20px', borderRadius: '50%', border: '2px solid red' }} />
          <div style={{ fontSize: '30px', textAlign: 'center', marginBottom: '20px' }}>
            Are you sure ?
          </div>
          <div style={{ textAlign: 'center', width: '320px', marginBottom: '20px' }}>Do you really want to delete this item? This Process cannot be undone. </div>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <button
              onClick={handleCloseDelete}
              style={{ backgroundColor: "#9c9d9e", color: "white", padding: "7px 15px", borderRadius: "5px", marginRight: '10px', width: '100px', border: '0px' }}
            >
              Cancel
            </button>
            <button
              onClick={deletePost}
              style={{ backgroundColor: "red", color: "white", padding: "7px 15px", borderRadius: "5px", width: '100px', border: "0px" }}
            >
              Yes
            </button>
          </DialogActions>
        </div>
      </Dialog>

      <Dialog open={openNew} onClose={handleClose} fullWidth={true} maxWidth="xl">
        <DialogContent>
          <form onSubmit={handleEditSubmit}>
            <div
              style={{
                width: "100%",
                height: "4rem",
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                marginTop: "0rem",
                display: "flex",
                alignItems: "center",
                padding: "1rem",
                justifyContent: "space-between",
                boxShadow: "0px 4px 4px 2px rgb(231, 231, 231)",
              }}
            >
              <span style={{ color: "#CB8B65", fontSize: "1.2rem", fontWeight: "600" }}>
                Edit Blog Post
              </span>
              <button type='submit' style={{ backgroundColor: '#CB8B65', padding: '10px 15px', color: 'white', borderRadius: '10px' }}>Publish</button>
            </div>

            <div style={{ padding: '1rem 1.5rem 0.5rem 1.5rem', fontSize: '20px' }}>Edit Blog Post</div>
            <hr style={{ margin: '0px', border: '1px solid' }} />

            <div style={{ padding: '1rem 1.5rem', display: 'flex', flexDirection: "column", gap: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <label htmlFor="file" style={{ cursor: 'pointer', height: '100px', width: '100%', border: '1px dashed #000', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div><CloudUploadIcon /></div>
                    <div style={{ fontSize: '20px' }}>
                      {imageName ? imageName : 'Upload a high quality Image'}
                    </div>
                  </div>
                </label>
                <input accept="image/*" required id="file" type="file" onChange={handleFileChange} style={{ display: 'none' }} value={''} />
              </div>

              <div style={{ display: 'flex', gap: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                  <label style={{ fontSize: '16px', marginBottom: '6px', marginLeft: '10px', fontWeight: '500' }} htmlFor="category">Category</label>
                  <select required value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    id="category"
                    style={{ height: '40px', borderRadius: '10px', border: '1px solid', paddingLeft: '10px' }}>
                    <option value=""></option>
                    <option value="For Students">For Students</option>
                    <option value="For Parents">For Parents</option>
                    <option value="For Teachers">For Teachers</option>
                  </select>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                  <label style={{ fontSize: '16px', marginBottom: '6px', marginLeft: '10px', fontWeight: '500' }} htmlFor="authorName">Author Name</label>
                  <input value={authorName} required onChange={(e) => setAuthorName(e.target.value)}
                    id="authorName"
                    style={{ height: '40px', borderRadius: '10px', border: '1px solid', paddingLeft: '10px' }}
                    type="text"
                    placeholder="Enter Author Name"
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: "column", width: '33%' }}>
                  <label style={{ fontSize: '16px', marginBottom: '6px', marginLeft: '10px', fontWeight: '500' }} htmlFor="date">Select Date</label>
                  <input required  value={date} onChange={(e) => setDate(e.target.value)}
                    id="date"
                    style={{ height: '40px', borderRadius: '10px', border: '1px solid', paddingLeft: '10px' }}
                    type="date"
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontSize: '16px', marginBottom: '6px', marginLeft: '10px', fontWeight: '500' }} htmlFor="title">Title</label>
                <input required value={title} onChange={(e) => setTitle(e.target.value)} style={{ height: '40px', borderRadius: '10px', border: '1px solid', paddingLeft: '10px' }} type="text" placeholder="Enter Title" />
              </div>

              <div>
                <ReactRte required
                  value={editorValue}
                  onChange={handleChange}
                  editorStyle={{
                    overflowY: 'auto',
                    maxHeight: '250px',
                    height: '100%',
                  }}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} style={{ backgroundColor: "#CB8B65", color: "white", padding: "10px 15px", borderRadius: "5px" }}>
            Cancel
          </button>
          {/* <button type='submit' style={{ backgroundColor: "#CB8B65", color: "white", padding: "10px 15px", borderRadius: "5px" }}>
            Save
          </button> */}
        </DialogActions>
      </Dialog>

      <Paper sx={{ width: "96%", overflow: "hidden", scrollBehavior: "smooth", marginLeft: "1rem", marginTop: '30px' }}>
        <TableContainer sx={{ maxHeight: 360 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "#CB8B65",
                      fontWeight: "500",
                      color: "#ffffff",
                      fontSize: "1.1rem",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) && data.map((item, index) => (
                <TableRow key={item.id} hover>
                  <TableCell>{index+1}</TableCell>
                  <TableCell>{item.postTitle}</TableCell>
                  <TableCell>{item.postAuthor}</TableCell>
                  <TableCell>{item.postCategory}</TableCell>
                  <TableCell>{item.postDate}</TableCell>
                  <TableCell>
                    <IconButton>
                      <EditIcon onClick={() => { fetchEdit(item.id) }} />
                    </IconButton>
                    <IconButton>
                      <DeleteIcon onClick={() => { setDeleteId(item.id); setOpen(true); }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default Allpost;
