import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import FooterBack from "../../../assets/FooterBack.svg";
import LogoFooter from "../../../assets/LogoFooter.svg";
import location2 from "../../../assets/location2.svg";
import Phone from "../../../assets/Phone.svg";
import instagram from "../../../assets/instagram.svg";
import facebook from "../../../assets/facebook.svg";
import twitter from "../../../assets/twitter.svg";
import yt from "../../../assets/youtube.png";
import linkedin from "../../../assets/linkedin.png";
import Email from "../../../assets/Email.svg";
import GooglePlay from "../../../assets/GooglePlay.svg";
import AppStore from "../../../assets/AppStore.svg";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "bootstrap";
import { YouTube } from "@mui/icons-material";


const Footer = () => {
  const email = "kanhahometutions@gmail.com"; // Replace with the recipient's email
  const subject = "";
  const body = "";
  const currentYear = new Date().getFullYear();


  const handleEmailClick = () => {
    // Create the mailto link
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    console.log('hiii',mailtoLink)
    // Redirect to the mailto link
    window.open(mailtoLink, "_blank");
  };
  const isXs = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const handleClickfortutor = () => {
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.kanhahometutions.tutor";
    window.open(playStoreUrl, "_blank");
  };

  const handleClickforstudent = () => {
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.kanhahometutions.student";
    window.open(playStoreUrl, "_blank");
  };

  const khtinstagram = () => {
    const playStoreUrl =
      "https://www.instagram.com/kanha_home_tuition?igsh=c29rOWFyY29wdm5m";
    window.open(playStoreUrl, "_blank");
  };

  const khtfacebook = () => {
    const playStoreUrl =
      "https://www.facebook.com/KanhaHomeTuitions?mibextid=ZbWKwL";
    window.open(playStoreUrl, "_blank");
  };
 
  const khttwitter = () => {
    const playStoreUrl =
      "https://x.com/kanhahometution?s=21";
    window.open(playStoreUrl, "_blank");
  };

  const youtube = () => {
    const playStoreUrl =
      "https://www.youtube.com/@Kanhahome";
    window.open(playStoreUrl, "_blank");
  };

 
  const khtlinkedin = () => {
    const playStoreUrl =
      "https://www.linkedin.com/company/kanha-home-tuitions/posts/?feedView=all";
    window.open(playStoreUrl, "_blank");
  };

  return (
    <>
      {/* Footer */}
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: isXs ? "" : "center",
          backgroundColor: isXs ? "" : "#20140E",
          paddingBottom: isXs ? "" : "15%",
        }}
      >
        <img
          src={FooterBack}
          alt="Frame24"
          style={{
            position: "absolute",
            width: "100%",
            maxHeight: "100%",
            zIndex: -2,
            display: isXs ? "" : "none",
          }}
        />
        <Grid
          item
          xs={10}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          container
          spacing={3}
          sx={{ marginTop: "5%" }}
        >
          <Box style={{ marginTop: "7%", display: "flex" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={LogoFooter}
                  alt="LogoFooter"
                  style={{ width: "70%" }}
                />
              </Box>
              <Box
                style={{
                  width: isXs ? "60%" : "95%",
                  color: "white",
                  fontSize: isXs ? "1vw" : "3.8vw",
                  textAlign: isXs ?  "left" : "center",
                  marginLeft: isXs ? "3.5rem" : "0rem",
                  marginTop: isXs ? "" : "2%",
                }}
              >
                Empower your learning journey with Kanha Home Tuitions—where
                potential meets possibility right at your doorstep!
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          container
          spacing={3}
          sx={{ marginTop: "8%" }}
        >
          <Box
            style={{
              marginTop: "10%",
              fontSize: "1vw",
              minWidth: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{ width: "80%", display: "flex", justifyContent: "center" }}
            >
              <Box sx={{ width: "60%" , display: 'flex' , flexDirection: 'column' , gap: '0rem' }}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  {" "}
                  <button
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      width: "100%",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Box
                      style={{
                        color: "white",
                        marginLeft: "5%",
                        marginTop: "0%",
                        fontSize: isXs ? "" : "3.8vw",
                      }}
                    >
                      Home
                    </Box>
                  </button>
                </Link>
                <Link to="/blogs" style={{ textDecoration: "none" }}>
                  <button
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      width: "100%",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Box
                      style={{
                        color: "white",
                        marginLeft: "5%",
                        marginTop: "5%",
                        fontSize: isXs ? "" : "3.8vw",
                      }}
                    >
                      Blogs
                    </Box>
                  </button>
                </Link>
                <Link to="/privacy-policy" style={{ textDecoration: "none" }}>
                  <button
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      width: "100%",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Box
                      style={{
                        color: "white",
                        marginLeft: "5%",
                        marginTop: "5%",
                        fontSize: isXs ? "" : "3.8vw",
                      }}
                    >
                      Privacy Policy
                    </Box>
                  </button>
                </Link>
                <Link to="/refund-policy" style={{ textDecoration: "none" }}>
                  {" "}
                  <button
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      width: "100%",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Box
                      style={{
                        color: "white",
                        marginLeft: "5%",
                        marginTop: "5%",
                        fontSize: isXs ? "" : "3.8vw",
                      }}
                    >
                      Refund Policy
                    </Box>
                  </button>
                </Link>
                <Link to="/terms-conditions" style={{ textDecoration: "none" }}>
                  {" "}
                  <button
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      width: "100%",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Box
                      style={{
                        color: "white",
                        marginLeft: "5%",
                        marginTop: "5%",
                        fontSize: isXs ? "" : "3.8vw",
                      }}
                    >
                      Terms & Conditions
                    </Box>
                  </button>
                </Link>

                <Link
                    to="/sitemap"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <button
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        width: "100%",
                        justifyContent: isXs ? "" : "center",
                      }}
                    >
                      <Box
                        style={{
                          color: "white",
                          marginLeft: "5%",
                          marginTop: "5%",
                          fontSize: isXs ? "" : "3.8vw",
                        }}
                      >
                        Sitemap
                      </Box>
                    </button>
                  </Link>
                  <Link
                    to="/contact-us"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <button
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        width: "100%",
                        justifyContent: isXs ? "" : "center",
                      }}
                    >
                      <Box
                        style={{
                          color: "white",
                          marginLeft: "5%",
                          marginTop: "5%",
                          fontSize: isXs ? "" : "3.8vw",
                        }}
                      >
                        Contact Us
                      </Box>
                    </button>
                  </Link>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          container
          spacing={3}
          sx={{ marginTop: "8%" }}
        >
          <Box
            style={{
              marginRight: "10%",
              marginTop: "10%",
              fontSize: "1vw",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{display: "flex" , flexDirection: "column" , gap: "1rem"}}>
              <Box className="d-flex align-items-center ">
                <img src={location2} alt="location2" width="8%" />
                <Box
                  style={{
                    color: "white",
                    marginLeft: "5%",
                    minWidth: isXs ? "95%" : "95%",
                    fontSize: isXs ? "" : "3.8vw",
                  }}
                >
                  Plot No. 45, Vaishali Nagar, Jaipur
                </Box>
              </Box>
              <Box className="d-flex align-items-center">
                <img src={Phone} alt="Phone" width="7%" />
                <Box
                  style={{
                    color: "white",
                    marginLeft: "5%",
                    
                    fontSize: isXs ? "" : "3.8vw", 
                  }}
                >
                  <a href="tel:+91-6376400386" style={{ fontSize: '15px', color: 'white' , textDecoration: 'none' }} >
                  +91-6376400386</a>
                 
                </Box>
              </Box>
              <Box className="d-flex align-items-center">
                <img src={Email} alt="Email" width="7%" />
                <Box
                  style={{
                    color: "white",
                    marginLeft: "5%",
                    marginTop: "0%",
                    fontSize: isXs ? "" : "3.8vw",
                  }}
                >
                  <div onClick={handleEmailClick}>info@kanhahometutions.com</div>

               
                </Box>
              </Box>
              <Box className="d-flex align-items-center">
                <img src={Email} alt="Email" width="7%" />
                <Box
                  style={{
                    color: "white",
                    marginLeft: "5%",
                    marginTop: "0%",
                    fontSize: isXs ? "" : "3.8vw",
                  }}
                >
                  <div onClick={handleEmailClick}>kanhahometutions@gmail.com</div>

               
                </Box>
              </Box>
              <Box sx={{display: "flex" , gap: "1.5rem" , marginTop: "1rem"}}>
              <Box><img style={{cursor: 'pointer'}} onClick={khtinstagram} src={instagram} alt="" width={30} /></Box>
              <Box><img onClick={khtfacebook} style={{cursor: 'pointer'}} src={facebook} alt="facebook"  width={30} /></Box>
              <Box><img style={{cursor: 'pointer'}}  onClick={khttwitter} src={twitter} alt="" width={30} /></Box>
              <Box><img  style={{cursor: 'pointer'}} onClick={youtube} src={yt} alt="" width={30} /></Box>
              <Box><img style={{cursor: 'pointer'}}  onClick={khtlinkedin} src={linkedin} alt="" width={30} /></Box>
              </Box>
              
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          spacing={3}
          sx={{ marginTop: "6%" }}
        >
          <Box
            style={{
              marginTop: isXs ? "10%" : "1%",
              minWidth: "100%",
              textAlign: "left",
              display: "flex",
              flexDirection: isXs ? "column" : "row",
              gap: "1rem",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: isXs ? "1rem" : ".5rem",
              }}
            >
              <Box style={{ color: "white" }}>For Tutor</Box>

              <img
              
                onClick={handleClickfortutor}
                src={GooglePlay}
                alt="GooglePlay"
                style={{ width: isXs ? "60%" : "90%", cursor: 'pointer' }}
              />
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: isXs ? "1rem" : ".5rem",
              }}
            >
              <Box style={{ color: "white" }}>For Learner</Box>

              <img
                onClick={handleClickforstudent}
                src={GooglePlay}
                alt="GooglePlay"
                style={{ width: isXs ? "60%" : "90%", cursor: 'pointer'  }}
              />
            </Box>
            {/* <Box style= {{color: "white"}}>
                            For Learner
                        </Box>
                        <Link to="https://play.google.com/store/apps/details?id=com.kanhahometutions.tutor">
                        <img src={GooglePlay} alt="GooglePlay" width="50%" />
                        </Link> */}
          </Box>
        </Grid>
      </Grid>

      {/* footer */}
      <Grid
        container
        className="py-3 px-2"
        sx={{
          marginTop: isXs ? "2%" : "",
          backgroundColor: "#A6653F",
          fontSize: isXs ? "1vw" : "3vw",
          color: "white",
        }}
      >
        <Box style={{ textAlign: isXs ? "start" : "center", margin: "0 auto" }}>
          &copy;{currentYear} Kanha Home Tutions. All Right Reserved{" "}
        </Box>
        <Box style={{ textAlign: isXs ? "end" : "center", margin: "0 auto" }}>
          Powered by Infomagine Softwares Pvt. Ltd.
        </Box>
      </Grid>
    </>
  );
};

export default Footer;
