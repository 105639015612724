import React from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from '../../Navigation/NavBar';
import Footer from '../LandingPage/Footer';

function BlogDetail() {
  const location = useLocation();
  const { state: blogData } = location;

  if (!blogData) {
    return <div>No blog data found.</div>;
  }

  return (
    <>
      <NavBar />
      <div style={{ padding: '4rem 6rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <img
            style={{ height: '400px', width: '100%' }}
            src={blogData.postFile}
            alt="Blog detail image"
          />
          <div style={{ display: 'flex', padding: '0px 0px' }}>
              <div style={{ color: '#CB8B65', textTransform: 'uppercase', fontWeight: 'bold' }}>{blogData.postCategory}</div>&nbsp; /
              <div>&nbsp;{blogData.postDate}</div>
            </div>
          <div style={{ fontSize: '32px', fontWeight: '500' }}>
            {blogData.postTitle}
          </div>
          <div dangerouslySetInnerHTML={{ __html: blogData.postDescription }}
             style={{marginTop: '20px',  marginBottom:'120px'}}>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BlogDetail;
