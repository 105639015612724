import { Box } from '@mui/material'
import React from 'react'
import Home from '../Home'
import LearningNeeds from '../LearningNeeds'
import Notifications from '../Notifications'
import Feedback from '../Feedback'
import AccountProfile from '../AccountProfile'
import AccountSettings from '../AccountSettings'
import Logout from '../Logout'
import DashboardHome from '../../DashboardTeacher/DashboardHome/DashboardHomeEnquiry'
import HomeClass from '../HomeClass'
// import HomeClass from '../HomeClass'


import { Routes, Route } from 'react-router-dom'
import DashboardHomeStudent from '../../DashboardTeacher/DashboardHome/DashboardHomeStudent'
import DashboardHomeEnquiryClick from '../../DashboardTeacher/DashboardHome/DashboardHomeEnquiryClick'
import ProfileInformation from '../../DashboardTeacher/DashboardProfile/ProfileInformation/ProfileInformation'
import ProfileEdit from '../../DashboardTeacher/DashboardProfile/ProfileInformation/ProfileEdit'
import ProfileTagline from '../../DashboardTeacher/DashboardProfile/ProfileInformation/ProfileTagline'
import PhoneNumber from '../../DashboardTeacher/DashboardProfile/ProfileInformation/PhoneNumber'
import Address from '../../DashboardTeacher/DashboardProfile/ProfileInformation/Address'
import QualificationExp from '../../DashboardTeacher/DashboardProfile/ProfileInformation/Qualification&Exp'
import ProfileEducation from '../../DashboardTeacher/DashboardProfile/ProfileInformation/ProfileEducation'
import Language from '../../DashboardTeacher/DashboardProfile/ProfileInformation/Language'
import Summary from '../../DashboardTeacher/DashboardProfile/ProfileInformation/Summary'
import SchoolLocation from '../../DashboardTeacher/DashboardProfile/ProfileInformation/SchoolLocation'
import Tutoring from '../../DashboardTeacher/DashboardProfile/ProfileInformation/Tutoring'
import ClassConducted from '../../DashboardTeacher/DashboardProfile/ClassConducted/ClassConducted'
import PricingClass from '../../DashboardTeacher/DashboardProfile/Pricing/PricingClass'
import ClassCourse from '../../DashboardTeacher/DashboardProfile/Pricing/ClassCourse'
import Reviews from '../../DashboardTeacher/DashboardProfile/Review/Reviews'
import TutorProfile from '../../DashboardTeacher/DashboardProfile/Profile/TutorProfile'
import CloseAccount from '../../DashboardTeacher/Account/CloseAccount'
import SubscriptionPlan from '../../DashboardTeacher/Subscription/SubscriptionPlan'
import SubscriptionHistory from '../../DashboardTeacher/Subscription/SubscriptionHistory'
import CreditHistory from '../../DashboardTeacher/Credit/CreditHistory'
import TabHome from '../../DashboardTeacher/DashboardHome/TabHome'
import TeacherReviews from '../TeacherReviews'
import EnquiryDetails from '../EnquiryDetails'
import CreateReview from '../CreateReview'
import HelpAndSupport from '../HelpAndSupport'
import NotificationTeacher from '../../DashboardTeacher/NotificationTeacher'
import AddClass from '../../DashboardTeacher/DashboardProfile/ClassConducted/AddClass'
import AdminDashboard from '../../AccountAdmin/AdminDashboard'
import AdminCreatePost from '../../AccountAdmin/AdminCreatePost'
import Protected from '../../Protected'
import StudentList from '../../AccountAdmin/StudentList'
import TeacherList from '../../AccountAdmin/TeacherList'
import HelpSupport from '../../DashboardTeacher/Account/HelpSupport'
import Subject from '../../InquiryStudent/Subject'
import ManageCredit from '../../AccountAdmin/ManageCredit'
import Location from '../../InquiryStudent/Location'
import Education from '../../InquiryStudent/Education'
import SubSubjects from '../../InquiryStudent/SubSubjects'
import Classes from '../../InquiryStudent/Classes'
import Fee from '../../InquiryStudent/Fee'
import Days from '../../InquiryStudent/Days'
import Start from '../../InquiryStudent/Start'
import Online from '../../InquiryStudent/Online'
import Gender from '../../InquiryStudent/Gender'
import ManageEnquiry from '../../AccountAdmin/ManageEnquiry'
import ManageFeedback from '../../AccountAdmin/ManageFeedback'
import BoardAndClasses from '../../../Navigation/BoardAndCourses'
import ClassesStudymaterial from '../../../Navigation/ClassesStudymaterial'
import SubjectsStudyMaterial from '../../../Navigation/SubjectsStudyMaterial'
import Notes from '../../../Navigation/Notes'
import Allpost from '../../Blogs/Allpost'
import AddPost from '../../Blogs/AddPost'

const RightLayout = () => {
  return (
    <Box flex={9} sx={{ backgroundColor: 'white', width: "1rem" , borderRadius: 2, minHeight: '80vh' }}>
      <>
          <Routes>
            <Route path='/home' exact element={<Protected Component={Home} />}></Route>

            <Route path='/homeclass/:itemId' exact element={<HomeClass />}></Route>
            <Route path='/teacherreviews/:teacherId' exact element={<TeacherReviews />}></Route>
            <Route path='/enquirydetails' exact element={<EnquiryDetails />}></Route>
            <Route path='/write-review' exact element={<CreateReview />}></Route>
            <Route path='/learningneeds' exact element={<Subject />}></Route>
            <Route path='/location' exact element={<Location />}></Route>
            <Route path='/education' exact element={<Education />}></Route>
            <Route path='/subsubjects' exact element={<SubSubjects />}></Route>
            <Route path="/classes" element={<Classes />} />
            <Route path='/fee' exact element={<Fee />}></Route>
            <Route path='/days' exact element={<Days />}></Route>
          <Route path='/start' exact element={<Start />}></Route>
          <Route path='/online' exact element={<Online />}></Route>
          <Route path='/gender' exact element={<Gender />}></Route>
            <Route path='/notifications' exact element={<Notifications />}></Route>
            <Route path='/help-support' exact element={<HelpAndSupport />}></Route>
            <Route path='/feedback' exact element={<Feedback />}></Route>
            <Route path='/profile' exact element={<AccountProfile />}></Route>
            <Route path='/settings' exact element={<AccountSettings />}></Route>
            <Route path='/logout' exact element={<Logout />}></Route>

            {/* admin routes */}
            <Route path='/admin' exact element={<Protected Component={AdminDashboard} />}></Route>
            <Route path='/createPost' exact element={<Protected Component={AdminCreatePost} />}></Route>
            <Route path="/studentList" element={<Protected Component={StudentList} />} />
            <Route path="/teacherList" element={<Protected Component={TeacherList} />} />
            <Route path="/manageCredit" element={<Protected Component={ManageCredit} />} />
            <Route path="/manageEnquiry" element={<Protected Component={ManageEnquiry} />} />
            <Route path="/manageFeedback" element={<Protected Component={ManageFeedback} />} />
            <Route path="/board&courses" element={<Protected Component={BoardAndClasses} />} />
            <Route path="/board/classes" element={<Protected Component={ClassesStudymaterial} />} />
            <Route path="/board/classes/subjects" element={<Protected Component={SubjectsStudyMaterial} />} />
            <Route path="/board/classes/subjects/Notes" element={<Protected Component={Notes} />} />
            <Route path="/blogs/all-post" element={<Protected Component={Allpost} />} />
            <Route path="/blogs/add-post" element={<Protected Component={AddPost} />} />


            {/* <Route path='/tabhome' exact element={<TabHome />}></Route> */}
            <Route path='/dashboard-home' exact element={<DashboardHome />}></Route>
            <Route path="/dashboardhomestudent" element={<DashboardHomeStudent />} />
            <Route path="/dashboard-home-enquiry/:itemId" element={<DashboardHomeEnquiryClick />} />
            <Route path="/profileinformation" element={<Protected Component={ProfileInformation} />} />

            <Route path="/profileedit" element={<Protected Component={ProfileEdit} />} />
            <Route path="/profiletagline" element={<ProfileTagline />} />
            <Route path="/phonenumber" element={<Protected  Component={PhoneNumber} />} />
            <Route path="/address" element={<Protected Component={Address} />} />
            <Route path="/qualification" element={<Protected Component={QualificationExp} />} />
            <Route path="/profileeducation" element={<Protected Component={ProfileEducation} />} />
            <Route path="/language" element={<Protected Component={Language} />} />
            <Route path="/summary" element={<Protected Component={Summary} />} />
            <Route path="/schoollocation" element={<SchoolLocation />} />
            <Route path="/tutoring" element={<Protected Component={Tutoring} />} />
            <Route path="/classconducted" element={<Protected Component={ClassConducted} />} />
            <Route path="/add-class" element={<Protected Component={AddClass} />} />
            <Route path="/pricingclass" element={<PricingClass />} />
            <Route path="/classcourse" element={<Protected Component={ClassCourse} />} />
            <Route path="/reviews" element={<Protected Component={Reviews} />} />
            <Route path="/tutorprofile" element={<Protected Component={TutorProfile} />} />
            <Route path="/notification" element={<Protected Component={NotificationTeacher} />} />
            <Route path="/closeaccount" element={<Protected Component={CloseAccount} />} />
            <Route path="/support" element={<Protected Component={HelpSupport} />} />
            <Route path="/subscriptionplan" element={<Protected Component={SubscriptionPlan} />} />
            <Route path="/subscriptionhistory" element={<Protected Component={SubscriptionHistory} />} />
            <Route path="/credithistory" element={<Protected Component={CreditHistory} />} />
          </Routes>
      </>
    </Box>
  )
}

export default RightLayout
