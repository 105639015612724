import React from "react";
import Tutor2 from "../../../assets/Tutor2.svg";
import pencil2 from "../../../assets/pencil2.svg";
import bulb2 from "../../../assets/bulb2.svg";
import calculater from "../../../assets/calculater.svg";
import DottedArrow from "../../../assets/DottedArrow.svg";
import line2 from "../../../assets/line2.svg";
import line3 from "../../../assets/line3.svg";
import Group2081 from "../../../assets/Group2081.svg";
import Group35 from "../../../assets/Group35.svg";
import Group36 from "../../../assets/Group36.svg";
import arrow2 from "../../../assets/arrow2.svg";
import Scale from "../../../assets/Scale.svg";
import Frame from "../../../assets/Frame.svg";
import Leaderstyle from "../../../assets/Leaderstyle.svg";
import Leaderstyle2 from "../../../assets/Leaderstyle2.svg";
import Leaderstyle3 from "../../../assets/Leaderstyle3.svg";
import Leaderstyle4 from "../../../assets/Best tutor.svg";
import Leaderstyle5 from "../../../assets/Convenient.svg";
import Leaderstyle6 from "../../../assets/Affordable.svg";
import Pin1 from "../../../assets/Pin1.svg";
import Pin2 from "../../../assets/Pin2.svg";
import Pin3 from "../../../assets/Pin3.svg";
import Frame24 from "../../../assets/Frame24.svg";
import Frame25 from "../../../assets/Frame25.svg";
import GooglePlay from "../../../assets/GooglePlay.svg";
import location2 from "../../../assets/location2.svg";
import Phone from "../../../assets/Phone.svg";
import Email from "../../../assets/Email.svg";
import Group92 from "../../../assets/Group92.svg";
import LogoHeader from "../../../assets/LogoHeader.svg";
import GroupPhone from "../../../assets/mobile-screen 2.svg";
import PhoneDoodlePlus from "../../../assets/PhoneDoodlePlus.svg";
import PhoneDoodleMultiply from "../../../assets/PhoneDoodleMultiply.svg";
import PhoneDoodleBook from "../../../assets/PhoneDoodleBook.svg";
import PhoneDoodleDivide from "../../../assets/PhoneDoodleDivide.svg";
import Group2110 from "../../../assets/Group2110.svg";
import Group2111 from "../../../assets/Group2111.svg";
import Group221 from "../../../assets/Group221.svg";
import NoteBook from "../../../assets/NoteBook.svg";
import Globe from "../../../assets/Globe.svg";
import Step1 from "../../../assets/Step1.svg";
import Step2 from "../../../assets/Step2.svg";
import Step3 from "../../../assets/Step3.svg";
import headerimg from "../../../assets/Header-img.png";
import class1_6 from "../../../assets/1-6.jpg";
import class7 from "../../../assets/7.jpg";
import class8 from "../../../assets/8.jpg";
import class9 from "../../../assets/9.jpeg";
import class10 from "../../../assets/10.jpg";
import class11 from "../../../assets/11.jpg";
import class12 from "../../../assets/12.jpg";
import IIT from "../../../assets/IIT.jpeg";
import Neet from "../../../assets/Neet.jpeg";
import NDA from "../../../assets/NDA.jpg";
import CheckImg from "../../../assets/check.png";
import teacherImg from "../../../assets/TeacherNew.svg";
import QuicklinkBg from "../../../assets/QuicklinkBG.svg";
import jaipur from '../../../assets/Jaipur.png'
import mumbai from '../../../assets/Mumbai.png'
import Bengaluru from '../../../assets/bangalore.png'
import kathmandu from '../../../assets/kathmandu.png'
import Delhi from '../../../assets/Delhi.png'
import Chennai from '../../../assets/Chennai.png'
import Ahmedabad from '../../../assets/Ahmedabad.png'
import Kolkata from '../../../assets/kolkata.png'
import hyderabad from '../../../assets/hyderabad-charminar.png'
import Lucknow from '../../../assets/Lucknow.png'
import Pune from '../../../assets/pune.png'
import ArrowNew from "../../../assets/ArrowNew.svg";
import Linenew from "../../../assets/Linenew.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { TextareaAutosize } from "@mui/base";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import line4 from "../../../assets/line4.svg";
import cloudshape from "../../../assets/cloudshape.svg";
import teacherteaching from "../../../assets/teacherteaching.svg";
import Stack from "@mui/material/Stack";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { styled, css, style, padding } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Container,
  Grid,
  Typography,
  Button,
  Dialog,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { yupResolver } from "@hookform/resolvers/yup";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import WhatAppMobile from "./WhatAppMobile";
import { Description, ExpandMoreOutlined } from "@mui/icons-material";
import { Dropdown } from "react-bootstrap";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import ListItemButton from "@mui/material/ListItemButton";
import SwitchAccountOutlinedIcon from "@mui/icons-material/SwitchAccountOutlined";
import Collapse from "@mui/material/Collapse";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import NavBar from "../../Navigation/NavBar";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: "100%",
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: "100%",
    marginTop: "50px",
  },
}));

const themeColors = ["#ff5733", "#3498db", "#2ecc71"];

const Landing = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Kanha Home Tutions",
    url: "https://www.kanhahometutions.com/",
    logo: "https://www.kanhahometutions.com/static/media/LogoHeader.5eb7d516de8b950fe8230235cda93e37.svg",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+91 6376400386",
      contactType: "customer service",
      contactOption: "TollFree",
      areaServed: "IN",
      availableLanguage: ["Hindi", "en"],
    },
    sameAs: [
      "https://x.com/kanhahometution?s=21",
      "https://www.instagram.com/kanha_home_tuition?igsh=c29rOWFyY29wdm5m",
      "https://www.youtube.com/@Kanhahome",
      "https://pin.it/6ei6C46br",
      "https://www.tumblr.com/kanhahometutions",
      "https://www.kanhahometutions.com/",
    ],
  };

  const [smsIdNew, setSmsIdNew] = useState(null);
  const [studentQueryFormData, setStudentQueryFormData] = useState(null);
  const navigate = useNavigate();
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    fullName: yup.string().required(),
    pincode: yup.string().required(),
    area: yup.string().required(),
    city: yup.string().required(),
    mobileNumber: yup.string().required(),
    subject: yup.string().required(),
    course: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      classMode: "1",
    },
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const [selectedColor, setSelectedColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [blogBox, setBlogBox] = useState([]);


  const onSubmitHandler = async (data) => {
    setOtpNew("");
    setLoading(true);

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      date
    );
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      hour12: false,
    }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(
      date
    );
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(
      date
    );

    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("area", data.area);
    formData.append("fullName", data.fullName);
    formData.append("pincode", data.pincode);
    formData.append("course", data.course);
    formData.append("subject", data.subject);
    formData.append("classMode", data.classMode);
    formData.append("city", data.city);
    formData.append("mobile", data.mobileNumber);
    formData.append("token", CurrentDateTime);
    formData.append("smsType", "generalQuery");
    setStudentQueryFormData(formData);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/notifySMS",
        formData,
        config
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(false);
        setOpenOtpNew(true);
        setOpen(false);
        setSmsIdNew(response.data.data.smsID.toString());
        reset();
      } else {
        toast.warning(response.data.message ?? "Something Went Wrong");
        setLoading(false);
      }
    } catch (error) {
      toast.error(
        error?.response.data.message ?? "An error occurred. Please try again."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * themeColors.length);
    setSelectedColor(themeColors[randomIndex]);
  }, []);

  const handleResendOtpNew = async (e) => {
    e.preventDefault();
    setLoading2(true);

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      date
    );
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      hour12: false,
    }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(
      date
    );
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(
      date
    );
    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    const tempFormData = new FormData();
    tempFormData.append("smsId", smsIdNew);
    tempFormData.append("mobile", studentQueryFormData.get("mobile"));
    tempFormData.append("smsType", "generalQuery");
    tempFormData.append("token", CurrentDateTime);

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/resendOTP",
        tempFormData
      );

      if (response.data.success.toString() === "true") {
        toast.success(response.data.message);
        setSmsIdNew(response.data.data.smsID.toString());
        setLoading2(false);
      } else {
        toast.error(response.data.message);
        setLoading2(false);
      }
    } catch (error) { }
  };

  const handleSubmitOtpNew = async (e) => {
    e.preventDefault();
    setLoading(true);

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      date
    );
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      hour12: false,
    }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(
      date
    );
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(
      date
    );
    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    const formData = new FormData();
    formData.append("email", studentQueryFormData.get("email"));
    formData.append("area", studentQueryFormData.get("area"));
    formData.append("fullName", studentQueryFormData.get("fullName"));
    formData.append("pincode", studentQueryFormData.get("pincode"));
    formData.append("course", studentQueryFormData.get("course"));
    formData.append("subject", studentQueryFormData.get("subject"));
    formData.append("classMode", studentQueryFormData.get("classMode"));
    formData.append("city", studentQueryFormData.get("city"));
    formData.append("mobile", studentQueryFormData.get("mobile"));
    formData.append("token", CurrentDateTime);
    formData.append("smsType", "generalQuery");
    formData.append("smsId", smsIdNew);
    formData.append("smsOtp", otpNew);
    setStudentQueryFormData(formData);

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/verifyOtp",
        formData
      );

      if (response.data.success.toString() === "true") {
        toast.success(response.data.message);
        setOpenOtpNew(false);
        setSmsIdNew(response.data.data.smsID.toString());
        setLoading(false);
        setOtpNew("");
      } else {
        toast.error(response.data.message ?? "OTP does not match.");
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
      setLoading(false);
    }
  };

  const [quickLinks, setQuickLinks] = useState([]);

  async function getQuickLinks() {
    try {
      const response = await axios.get(
        `https://app.kanhahometutions.com/api/v1/quick-links`
      );

      setQuickLinks(response.data);

    } catch (error) {
      console.error("Error fetching:", error);
    }
  }

  async function blogs() {

    const token = {
      token: 'abcd123'
    }
    try {
      const response = await axios.post(
        `https://app.kanhahometutions.com/api/v1/blogPosts`, token
      );

      setBlogBox(response.data.data);
      console.log(response.data.data);


    } catch (error) {
      console.error("Error fetching:", error);
    }
  }

  useEffect(() => {
    getQuickLinks();
    blogs();
  }, []);

  const sxStyle = {
    display: "flex",
    justifyContent: { xs: "center" },
    alignItems: "center",
  };

  const isXs = useMediaQuery("(min-width:600px) and (max-width:3000px)");

  const markerStyle = {
    color: "#B64502",
  };

  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const [selectedText, setSelectedText] = useState("");
  const [seoPages, setSeoPages] = useState([]);

  const toggleSidebar = (text, seoPages) => {
    setIsOpen((prev) => !prev);
    setSelectedText(text);
    setSeoPages(seoPages);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const styles = {
    grid: {
      display: "grid",
      gridTemplateColumns: isXs ? "repeat(3, 1fr)" : "repeat(1, 1fr)",
      gap: "20px",
    },
    box: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
      backgroundColor: "#FFEBE2",
      border: "1px solid #ccc",
      borderRadius: "7px",
      boxShadow: "7px 7px 1px rgba(10, 10, 10, 0.17)",
      height: "3.7rem",
      width: "18rem",
      fontWeight: "500",
      cursor: "pointer",
      textAlign: "left",
    },
  };

  const [openNew, setOpenNew] = useState(false);
  const handleClickOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const handleJobType = (e) => {
    setJobType(e.target.value);
  };

  const handlesalary = (e) => {
    setSalary(e.target.value);
  };

  const [name, setName] = useState("");
  const [institutename, setInstitutename] = useState("");
  const [emailaddress, setEmailaddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [address, setAddress] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobType, setJobType] = useState("");
  const [instituteType, setInstituteType] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [salary, setSalary] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [error, setError] = useState("");
  const [formNew, setFormNew] = useState([]);

  const handleformsubmitfirst = async (e) => {
    setLoading(true);
    e.preventDefault();

    const minsalary = parseInt(min);
    const maxsalary = parseInt(max);

    if (minsalary >= maxsalary) {
      toast.error(
        "Minimum value must be less than maximum value. Please try again."
      );
      return false;
    }

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      date
    );
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      hour12: false,
    }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(
      date
    );
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(
      date
    );

    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    const formDatanew = new FormData();
    formDatanew.append("fullName", name);
    formDatanew.append("instituteName", institutename);
    formDatanew.append("email", emailaddress);
    formDatanew.append("mobile", phoneNumber);
    formDatanew.append("pincode", pinCode);
    formDatanew.append("city", cityName);
    formDatanew.append("state", stateName);
    formDatanew.append("address", address);
    formDatanew.append("jobTitle", jobTitle);
    formDatanew.append("jobType", jobType);
    formDatanew.append("instituteType", instituteType);
    formDatanew.append("description", jobDescription);
    formDatanew.append("salaryType", salary);
    formDatanew.append("minSalary", min);
    formDatanew.append("maxSalary", max);
    formDatanew.append("token", CurrentDateTime);
    formDatanew.append("smsType", "generalEnquiry");

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/notifySMS",
        formDatanew,
        config
      );
      if (response.status === 200) {
        setOpenNew(false);
        setOpenOtp(true);
        setFormNew([response.data.data]);
        setLoading(false);
        setOtp("");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const [otp, setOtp] = useState("");
  const handleChangeOtp = (newValue) => {
    setOtp(newValue);
  };

  const [openOtp, setOpenOtp] = useState(false);

  const handleOpenotp = () => {
    setOpenOtp(true);
  };

  const handleCloseOtp = () => {
    setOpenOtp(false);
  };

  const [otpNew, setOtpNew] = useState("");
  const handleChangeOtpNew = (NewValue) => {
    setOtpNew(NewValue);
  };

  const [openOtpNew, setOpenOtpNew] = useState(false);

  const handleOpenotpNew = () => {
    setOpenOtpNew(true);
  };

  const handleCloseOtpNew = () => {
    setOpenOtpNew(false);
  };

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    setLoading(true);

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      date
    );
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      hour12: false,
    }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(
      date
    );
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(
      date
    );
    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    var smsID = formNew.map((item) => item.smsID.toString());

    const formDatanew = new FormData();
    formDatanew.append("fullName", name);
    formDatanew.append("instituteName", institutename);
    formDatanew.append("email", emailaddress);
    formDatanew.append("mobile", phoneNumber);
    formDatanew.append("pincode", pinCode);
    formDatanew.append("city", cityName);
    formDatanew.append("state", stateName);
    formDatanew.append("address", address);
    formDatanew.append("jobTitle", jobTitle);
    formDatanew.append("jobType", jobType);
    formDatanew.append("instituteType", instituteType);
    formDatanew.append("description", jobDescription);
    formDatanew.append("salaryType", salary);
    formDatanew.append("minSalary", min);
    formDatanew.append("maxSalary", max);
    formDatanew.append("token", CurrentDateTime);
    formDatanew.append("smsType", "generalEnquiry");
    formDatanew.append("smsId", smsID);
    formDatanew.append("smsOtp", otp);

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/verifyOtp",
        formDatanew
      );

      if (response.data.success.toString() === "true") {
        toast.success(response.data.message);
        setOpenOtp(false);
        setLoading(false);
        setOtp("");
      } else {
        toast.error(response.data.message ?? "OTP does not match.");
        setLoading(false);
      }
    } catch (error) {
      toast.error(
        error?.response.data.message ?? "An error occurred. Please try again."
      );
      setLoading(false);
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    setLoading2(true);

    let date = new Date();
    let randomnumber = Math.floor(Math.random() * 1000000);
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      date
    );
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    let hours = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      hour12: false,
    }).format(date);
    let minutes = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(
      date
    );
    let seconds = new Intl.DateTimeFormat("en", { second: "2-digit" }).format(
      date
    );
    let CurrentDateTime = `${year}${month}${day}${randomnumber}${hours}${minutes}${seconds}`;

    var smsID = formNew.map((item) => item.smsID.toString());
    // var smsIDNEW =  formNew.map(item => item.smsID.toString());

    const formDatanew = new FormData();
    formDatanew.append("smsId", smsID);
    formDatanew.append("mobile", phoneNumber);
    formDatanew.append("smsType", "generalEnquiry");
    formDatanew.append("token", CurrentDateTime);

    try {
      const response = await axios.post(
        "https://app.kanhahometutions.com/api/v2/resendOTP",
        formDatanew
      );

      if (response.data.success.toString() === "true") {
        toast.success(response.data.message);
        setFormNew([response.data.data]);
        setLoading2(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) { }
  };

  const [Menu, setMenu] = useState(false);

  const toggleMenu = () => {
    setMenu((prevOpen) => !prevOpen);
  };
  const [openSide, setOpenSide] = useState(false);

  const toggleDrawer = () => {
    setOpenSide(!openSide);
  };

  const [openDropdown, setOpenDropdown] = useState(false);

  const handleClickDropdown = () => {
    setOpenDropdown(true);
  };

  const handleCloseDropdown = () => {
    setOpenDropdown(false);
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>

      <WhatAppMobile />

      <NavBar />


      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "relative",
          backgroundColor: isXs ? "" : "#CB8B65",
          height: isXs ? "" : "45rem",
        }}
      >


        <img
          style={{
            width: "100%",
            display: isXs ? "" : "none",
            // objectFit: 'cover'
          }}
          src={headerimg}
          alt=""
        />
      </div>



      <div
        style={{
          position: "absolute",
          left: "0",
          right: "0",
          bottom: "45px",
          marginInline: "auto",
          width: "100%",
          display: isXs ? "" : "none",
        }}
      ></div>


      <div
        style={{
          position: "absolute",
          left: "0",
          right: "0",
          top: isXs ? "27%" : '18%',
          marginInline: "auto",
          width: "100%",
          padding: isXs ? "1rem 6rem" : "0rem",
          display: "flex",
          flexDirection: "column",
          gap: isXs ? "2rem" : "3rem",
          justifyContent: isXs ? "" : "center",
          alignItems: isXs ? "" : "center",
        }}
      >
        <div
          style={{
            display: isXs ? "none" : "none",
            gap: "130px",
            alignItems: "center",
          }}
        >
          <div>
            <img width={150} src={LogoHeader} alt="" />
          </div>
          <div>
            <MenuIcon
              onClick={toggleDrawer}
              style={{ color: "white", alignItems: "right", fontSize: "30px" }}
            />
          </div>{" "}
        </div>
        <StyledDrawer anchor="top" open={openSide} onClose={toggleDrawer}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 20px",
              backgroundColor: "#CB8B65",
              alignItems: "center",
              color: "white",
            }}
          >
            {/* <div>
              <img width={150} src={LogoHeader} alt="" />
            </div> */}

            <div
              style={{}}
              onClick={() => {
                toggleDrawer();
              }}
            >
              <MenuIcon />
            </div>
          </div>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleClickDropdown}>
                <ListItemText primary="One to One Class" />
                {openDropdown ? (
                  <ExpandMoreOutlined />
                ) : (
                  <ExpandMoreOutlined style={{ transform: "rotate(-90deg)" }} />
                )}
              </ListItemButton>
            </ListItem>
            <Collapse in={openDropdown} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem disablePadding>
                  <ListItemText onClick={handleOpen} primary="Online Tuition" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText onClick={handleOpen} primary="Home Tuition" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText
                    onClick={handleOpen}
                    primary="Hire a Personal Tutor"
                  />
                </ListItem>
              </List>
            </Collapse>
          </List>

          <List>
            <ListItem button>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href="/student-signin"
              >
                <ListItemText primary="Login as Student" />
              </a>
            </ListItem>
            <ListItem button>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href="/signin-teacher"
              >
                <ListItemText primary="Login as Tutor" />
              </a>
            </ListItem>
            <ListItem button>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href="/for-student"
              >
                <ListItemText primary="Request a Tutor" />
              </a>
            </ListItem>
            <ListItem button>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href="/for-teacher"
              >
                <ListItemText primary="Become a Tutor" />
              </a>
            </ListItem>
          </List>
        </StyledDrawer>
        <div
          style={{
            display: isXs ? "none" : "none",
            justifyContent: "space-between",
            flexDirection: isXs ? "" : "column",
            gap: isXs ? "" : "1rem",
            alignItems: "center",
          }}
        >
          <img width={200} src={LogoHeader} alt="" />
          <h3
            className="text-white"
            style={{
              fontSize: isXs ? "1.5vw" : "3.8vw",
              display: isXs ? "none" : "",
            }}
          >
            Welcome to Kanha Home Tutions
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: isXs ? "row" : "column",
              gap: isXs ? "2rem" : "0.7rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Dropdown align="start" drop="center">
              <Dropdown.Toggle
                style={{
                  display: isXs ? "" : "none",
                  backgroundColor: "transparent",
                  color: "white",
                  border: "none",
                  fontSize: "1rem",
                  fontWeight: "500",
                  padding: "3px 13px",
                  position: "relative",
                }}
              >
                One to One Class
                <span
                  style={{
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    borderRadius: "4px",
                    marginLeft: "10px",
                    position: "absolute",
                    right: "2px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "#FFDB7E",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: "0",
                      height: "0",
                      borderLeft: "5px solid transparent",
                      borderRight: "5px solid transparent",
                      borderTop: "5px solid #CB8B65",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  fontSize: "12px",
                  borderRadius: "5px",
                  padding: "15px 7px",
                }}
              >
                <Dropdown.Item
                  style={{ padding: " 0.2rem 1rem" }}
                  onClick={handleOpen}
                >
                  Online Tuition
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ padding: " 0.2rem 1rem" }}
                  onClick={handleOpen}
                >
                  Home Tuition
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ padding: " 0.2rem 1rem" }}
                  onClick={handleOpen}
                >
                  Hire a Personal Tutor
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown align="start" drop="center">
              <Dropdown.Toggle
                style={{
                  display: isXs ? "" : "none",
                  backgroundColor: "transparent",
                  color: "white",
                  border: "none",
                  fontSize: "1rem",
                  fontWeight: "500",
                  padding: "3px 13px",
                  position: "relative",
                }}
              >
                Online Courses
                <span
                  style={{
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    borderRadius: "4px",
                    marginLeft: "10px",
                    position: "absolute",
                    right: "2px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "#FFDB7E",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: "0",
                      height: "0",
                      borderLeft: "5px solid transparent",
                      borderRight: "5px solid transparent",
                      borderTop: "5px solid #CB8B65",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{ borderRadius: "5px", padding: "15px 7px" }}
              >
                <Dropdown.Item
                  style={{ fontSize: "12px" }}
                  onClick={handleOpen}
                >
                  Online class IIT-JEE maths{" "}
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ fontSize: "12px" }}
                  onClick={handleOpen}
                >
                  Online class IIT-JEE physics
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ fontSize: "12px" }}
                  onClick={handleOpen}
                >
                  Online class IIT-JEE chemistry
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ fontSize: "12px" }}
                  onClick={handleOpen}
                >
                  Online class NEET physics
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ fontSize: "12px" }}
                  onClick={handleOpen}
                >
                  Online class NEET chemistry
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ fontSize: "12px" }}
                  onClick={handleOpen}
                >
                  Online class NEET biology
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ fontSize: "12px" }}
                  onClick={handleOpen}
                >
                  Online class 12th maths CBSE
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ fontSize: "12px" }}
                  onClick={handleOpen}
                >
                  Online class 12 physics
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ fontSize: "12px" }}
                  onClick={handleOpen}
                >
                  Online class 12 chemistry
                </Dropdown.Item>
                <Dropdown.Item
                  style={{ fontSize: "12px" }}
                  onClick={handleOpen}
                >
                  {" "}
                  Online class 12 biology
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <button
              style={{
                border: "none",
                backgroundColor: "rgba(255, 255, 255, 0)",
                color: "white",
                borderRadius: "20rem",
                fontSize: "1rem",
                fontWeight: "500",
                padding: "0",
              }}
            >
              <Link
                to="/student-signin"
                style={{
                  color: "white",
                  textDecoration: "none",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Login as Student
              </Link>
            </button>

            <button
              style={{
                border: "none",
                backgroundColor: "rgba(255, 255, 255, 0)",
                color: "white",
                borderRadius: "20rem",
                fontSize: "1rem",
                fontWeight: "500",
                padding: "0",
              }}
            >
              <Link
                to="/signin-teacher"
                style={{
                  color: "white",
                  textDecoration: "none",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Login as Tutor
              </Link>
            </button>
            <div
              style={{
                position: "relative",
                marginLeft: "0rem",
                display: isXs ? "" : "none",
              }}
            >
              <div onClick={toggleMenu} style={{ cursor: "pointer" }}>
                <MenuIcon style={{ color: "white" }} />
              </div>
              {Menu && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    right: 0,
                    borderRadius: "5px",
                    minWidth: "170px",
                    backgroundColor: "white",
                    boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                    zIndex: 1000,
                  }}
                >
                  <div style={{ padding: "15px 7px" }}>
                    {/* <div style={{ padding: ' 0.2rem 1rem', fontSize:'12px' }}>Study Material </div> */}
                    {/* <div style={{ padding: ' 0.2rem 1rem', fontSize:'12px' }}><a style={{textDecoration:"none" , color:'black'}} href="/blog">Blog</a> </div> */}
                    <div style={{ padding: " 0.2rem 1rem", fontSize: "12px" }}>
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href="/for-student"
                      >
                        Request a Tutor
                      </a>
                    </div>
                    <div style={{ padding: " 0.2rem 1rem", fontSize: "12px" }}>
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href="/for-teacher"
                      >
                        Become a Tutor{" "}
                      </a>
                    </div>
                    <div style={{ padding: " 0.2rem 1rem", fontSize: "12px" }}>
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href="/contact-us"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isXs ? "" : "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: isXs ? "1.5rem" : "2rem",
              width: isXs ? "50%" : "",
              justifyContent: isXs ? "" : "center",
              alignItems: isXs ? "" : "center",
            }}
          >
            <h3
              className="text-white"
              style={{
                fontSize: isXs ? "1.5vw" : "4.8vw",
                display: isXs ? "" : "",
              }}
            >
              Welcome to Kanha Home Tutions
            </h3>

            <h1
              className="text-white fw-bold"
              style={{
                fontSize: isXs ? "3vw" : "6.6vw",
                lineHeight: isXs ? "3rem" : "1.8rem",
                textAlign: isXs ? "" : "center",
              }}
            >
              Best Tutor for <br /> Home and Online Tutions
            </h1>

            <img src={line2} style={{ width: "25%", maxWidth: "200px" }} />
            <div
              className="text-white"
              style={{
                width: isXs ? "100%" : "70%",
                fontSize: isXs ? "1vw" : "3.5vw",
                textAlign: isXs ? "" : "center",
              }}
            >
              <span>
                {" "}
                Connect with expert tutors for online or home tuitions, tailored
                to your learning needs and personal style. We offer classes for
                IIT-JEE, NEET, NDA, 12th, 11th, and all subjects across all
                grades, available all over India.
              </span>
            </div>

            <button
              className="py-2 px-2"
              onClick={handleOpen}
              type="button"
              style={{
                backgroundColor: "#FFDB7E",
                border: "none",
                color: "#866D2C",
                borderRadius: "5px",
                fontWeight: "bold",
                fontSize: isXs ? "1.5rem" : "1.5rem",
                width: isXs ? "80%" : "23rem",
                height: isXs ? "4rem" : "5rem",
                boxShadow: "5px  5px 2px rgb(0,0,0, .2)",
              }}
            >
              Get a Free Demo Class
              <img
                src={DottedArrow}
                style={{
                  width: "14%",
                  maxWidth: "25px",
                  marginLeft: "5%",
                }}
              />
            </button>
          </div>

          <Dialog
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={open}
            onClose={handleClose("backdropClick")}
            sx={{
              overflow: isXs ? "" : "auto",
              "& .MuiDialog-paper": {
                width: isXs ? '38%' : '600px',
                maxWidth: 'none',
              }
            }}
          >
            <div
              className="modal-content"
              style={{
                marginTop: isXs ? "" : "0%",
                textAlign: isXs ? "" : "center",
                width: isXs ? "100%" : "100%",
              }}
            >
              <Button
                style={{
                  position: "absolute",
                  right: "0%",
                  backgroundColor: "white",
                  borderRadius: "100%",
                  minWidth: "2rem",
                  color: "black",
                }}
                onClick={() => handleClose("close")}
              >
                X
              </Button>
              <div
                className="text-center py-2 fw-bold"
                style={{
                  backgroundColor: "#CB8B65",
                  width: "100%",
                  fontSize: isXs ? "28px" : "22px",
                  marginBottom: "5%",
                  display: "flex",
                  justifyContent: "center",
                  gap: "0.5rem",
                }}
              >
                <div
                  style={{
                    color: "#FFDB7E",
                  }}
                >
                  FREE
                </div>
                <div
                  style={{
                    color: "white",
                  }}
                >
                  REGISTRATION
                </div>
              </div>
              {/* <img src={Group92} style={{display: isXs ? "" : ""}}/> */}
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Box
                  className="d-flex justify-content-center mt-2"
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      m: 1,
                      width: isXs ? "23ch" : "75%",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "0.5px",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <div style={{ textAlign: isXs ? "end" : "center" }}>
                        <TextField
                          {...register("fullName")}
                          name="fullName"
                          id="fullName"
                          label="Full Name"
                          type="text"
                          placeholder="Enter your Full Name"
                          required
                          inputProps={{
                            style: {
                              height: "10px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            sx: { color: "black" },
                          }}
                          color="grey"
                          focused
                        // onChange={(e) =>
                        //     setFullName(e.target.value)
                        // }
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            color: "red",
                            marginRight: isXs ? "12%" : "",
                          }}
                        >
                          {errors.fullName?.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <div>
                        <TextField
                          {...register("mobileNumber")}
                          name="mobileNumber"
                          id="mobileNumber"
                          label="Mobile Number"
                          type="number"
                          placeholder="Enter your Mobile Number"
                          required
                          inputProps={{
                            style: {
                              height: "10px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            sx: { color: "black" },
                          }}
                          color="grey"
                          focused
                        // onChange={(e) =>
                        //     setMobileNumber(e.target.value)
                        // }
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            color: "red",
                            marginLeft: isXs ? "5%" : "",
                          }}
                        >
                          {errors.mobileNumber?.message}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  className="d-flex  mt-1 justify-content-between "
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      m: 1,
                      width: isXs ? "23ch" : "75%",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "0.5px",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <div style={{ textAlign: isXs ? "end" : "center" }}>
                        <TextField
                          {...register("email")}
                          name="email"
                          id="email"
                          label="Email"
                          type='"email'
                          placeholder="Enter your Email"
                          required
                          inputProps={{
                            style: {
                              height: "10px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            sx: { color: "black" },
                          }}
                          color="grey"
                          focused
                          style={{ marginLeft: "3%" }}
                        // onChange={(e) =>
                        //     setEmail(e.target.value)
                        // }
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            color: "red",
                            marginRight: isXs ? "20%" : "",
                          }}
                        >
                          {errors.email?.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <FormControl
                        component="fieldset"
                        style={{ marginLeft: "4%" }}
                      >
                        <FormLabel
                          component="legend"
                          style={{
                            marginBottom: "0%",
                            fontSize: isXs ? "0.9vw" : "3vw",
                            color: "black",
                            letterSpacing: "0px",
                          }}
                        >
                          Mode of Classes*
                        </FormLabel>
                        <Controller
                          // rules={{ required: true }}
                          control={control}
                          name="classMode"
                          render={({ field }) => (
                            <RadioGroup
                              row
                              aria-label="position"
                              {...field}
                              name="position"
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio color="primary" />}
                                label={
                                  <span
                                    style={{
                                      fontSize: isXs ? "1vw" : "3vw",
                                    }}
                                  >
                                    Online
                                  </span>
                                }
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio color="primary" />}
                                label={
                                  <span
                                    style={{
                                      fontSize: isXs ? "1vw" : "3vw",
                                    }}
                                  >
                                    Offine
                                  </span>
                                }
                              />
                            </RadioGroup>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  className="d-flex justify-content-center mt-1"
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      m: 1,
                      width: isXs ? "23ch" : "75%",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "0.5px",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <div style={{ textAlign: isXs ? "end" : "center" }}>
                        <TextField
                          name="ccb"
                          id="ccb"
                          label="Course / Class/ Board"
                          type='"text'
                          placeholder="Enter Course You Need"
                          {...register("course")}
                          required
                          inputProps={{
                            style: {
                              height: "10px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            sx: { color: "black" },
                          }}
                          color="grey"
                          focused
                        // onChange={(e) =>
                        //     setCource(e.target.value)
                        // }
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            color: "red",
                            marginRight: isXs ? "17%" : "",
                          }}
                        >
                          {errors.course?.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <div>
                        <TextField
                          name="subject"
                          {...register("subject")}
                          id="subject"
                          label="Subject"
                          type="text"
                          placeholder="Enter Subject You Need"
                          required
                          inputProps={{
                            style: {
                              height: "10px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            sx: { color: "black" },
                          }}
                          color="grey"
                          focused
                        // onChange={(e) =>
                        //     setSubject(e.target.value)
                        // }
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            color: "red",
                            marginLeft: isXs ? "5%" : "",
                          }}
                        >
                          {errors.subject?.message}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  className="d-flex justify-content-center mt-1"
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      m: 1,
                      width: isXs ? "13.8ch" : "75%",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      borderWidth: isXs ? "2px" : "2px",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                      <div style={{ textAlign: isXs ? "end" : "center" }}>
                        <TextField
                          {...register("city")}
                          name="city"
                          id="city"
                          label="City"
                          type='"text'
                          placeholder="Enter City Name"
                          required
                          inputProps={{
                            style: {
                              height: "10px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            sx: { color: "black" },
                          }}
                          color="grey"
                          focused
                        // onChange={(e) =>
                        //     setCity(e.target.value)
                        // }
                        />
                        <p style={{ fontSize: "12px", color: "red" }}>
                          {errors.city?.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                      <div style={{ textAlign: "center" }}>
                        <TextField
                          {...register("area")}
                          name="area"
                          id="area"
                          label="Area"
                          type="text"
                          placeholder="Enter Area Name"
                          required
                          inputProps={{
                            style: {
                              height: "10px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            sx: { color: "black" },
                          }}
                          color="grey"
                          focused
                        // onChange={(e) =>
                        //     setArea(e.target.value)
                        // }
                        />
                        <p style={{ fontSize: "12px", color: "red" }}>
                          {errors.area?.message}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                      <div tyle={{ textAlign: "start" }}>
                        <TextField
                          {...register("pincode")}
                          name="pincode"
                          id="pincode"
                          label="Pincode"
                          type="text"
                          placeholder="Enter Pincode"
                          required
                          inputProps={{
                            style: {
                              height: "10px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            sx: { color: "black" },
                          }}
                          color="grey"
                          focused
                        // onChange={(e) =>
                        //     setPincode(e.target.value)
                        // }
                        />
                        <p style={{ fontSize: "12px", color: "red" }}>
                          {errors.pincode?.message}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <div className="d-flex justify-content-center my-2">
                  {/* <button type="submit" className="btn btn-primary py-2 w-75 fw-bold" style={{ fontSize: '14px', border: '1px solid #CB8B65', backgroundColor: '#FFDB7E', color: '#866D2C', borderRadius: '0', zIndex: 3 }}>Get Free Demo Class</button> */}

                  {loading ? (
                    <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                      <CircularProgress color="success" />
                    </Stack>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary py-2 w-75 fw-bold"
                      style={{
                        fontSize: "14px",
                        border: "1px solid #CB8B65",
                        backgroundColor: "#FFDB7E",
                        color: "#866D2C",
                        borderRadius: "0",
                      }}
                    >
                      Next
                    </button>
                  )}
                </div>
              </form>
              <div
                style={{
                  height: "40px",
                  textAlign: "center",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                {" "}
                <span style={{ fontWeight: "700", fontSize: "14px" }}>
                  Are you a Tutor?
                </span>{" "}
                <a href="/signup-teacher">
                  <span style={{ fontSize: "14px", fontWeight: "700" }}>
                    Create Free Profile
                  </span>
                </a>
              </div>

              <div className="d-flex flex-row justify-content-between w-full mb-3">
                <div
                  className="flex-grow-1 text-center"
                  style={{
                    fontSize: "12px",
                    fontWeight: "900",
                  }}
                >
                  <small className="block">
                    <span className="">By signing up you agree to our </span>
                    <a href="/terms-conditions" style={{ color: "#0582D2" }}>
                      {" "}
                      Terms and Conditions
                    </a>
                  </small>
                </div>
              </div>
            </div>
          </Dialog>

          <div
            style={{
              display: "flex",
              justifyContent: isXs ? "end" : "center",
              alignItems: "center",
              marginTop: isXs ? "" : "2rem",
            }}
          >
            <img style={{ width: "90%" }} src={Tutor2} />
          </div>
        </div>
      </div>

      <Grid
        container
        style={{
          position: "relative",
          backgroundColor: isXs ? "transparent" : "#F6EBDC",
        }}
        sx={sxStyle}
      >
        <svg
          width="1549"
          height="1041"
          viewBox="0 0 1549 1041"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: isXs ? "-25%" : "0%",
            left: 0,
            width: "100%",
            height: "auto",
            zIndex: -2,
            display: isXs ? "" : "none",
          }}
        >
          <path
            d="M1548.23 0.310059H0.0720825V872.674C81.19 1035.96 492.48 983.73 773.672 956.998C979.637 937.417 1321.45 930.627 1548.23 1040.54V0.310059Z"
            fill="#F6EBDC"
          />
        </svg>
        <img
          src={Group2110}
          style={{
            position: "absolute",
            zIndex: -1,
            bottom: "-20%",
            display: isXs ? "" : "none",
            width: "98%",
          }}
        />
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div className="d-flex justify-content-center">
            <div
              className="text-center"
              style={{ marginTop: isXs ? "5.5%" : "12%" }}
            >
              <div
                style={{
                  color: "#A6653F",
                  fontSize: isXs ? "3vw" : "6vw",
                  fontWeight: "bold",
                }}
              >
                Looking Teachers for{" "}
                <br style={{ display: isXs ? "none" : "" }} /> Home &
                Online ?{" "}
              </div>
              <img
                src={Group2081}
                style={{
                  width: isXs ? "80%" : "90%",
                  marginTop: isXs ? "10%" : "12%",
                }}
              />
              <div
                className="text-center"
                style={{
                  marginTop: isXs ? "10%" : "12%",
                  marginBottom: isXs ? "" : "40%",
                }}
              >
                <button
                  className="py-2"
                  onClick={handleOpen}
                  type="button"
                  style={{
                    backgroundColor: "#FFDB7E",
                    width: isXs ? "55%" : "90%",
                    height: isXs ? "5rem" : "6rem",
                    border: "1px solid #CB8B65",
                    color: "#866D2C",
                    fontSize: isXs ? "1.2vw" : "4.5vw",
                    fontWeight: "bold",
                    boxShadow: "5px  5px 2px rgb(0,0,0, .2)",
                  }}
                >
                  START HIRING TEACHERS
                  <img
                    src={arrow2}
                    style={{
                      width: "9%",
                      maxWidth: "30px",
                      marginLeft: isXs ? "8%" : "3%",
                    }}
                  />
                </button>
                <Dialog
                  open={openNew}
                  onClose={() => { }}
                  maxWidth="md"
                  sx={{
                    "& .MuiBackdrop-root": {
                      backdropFilter: "blur(1px)",
                    },
                  }}
                  PaperProps={{
                    sx: {
                      padding: "rem",
                      overflowX: "hidden",
                    },
                  }}
                >
                  <div
                    className="text-center py-2 fw-bold"
                    style={{
                      position: "relative",
                      backgroundColor: "#CB8B65",
                      width: "100%",
                      color: "white",
                      height: isXs ? "" : "80px",
                      fontSize: isXs ? "28px" : "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: isXs ? "0.5rem" : "0.3rem",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                  >
                    <Button
                      onClick={handleCloseNew}
                      style={{
                        position: "absolute",
                        right: "0%",
                        top: "0",
                        backgroundColor: "white",
                        borderRadius: "100%",
                        minWidth: "2rem",
                        color: "black",
                      }}
                    >
                      X
                    </Button>
                    <span style={{ width: isXs ? "" : "70%" }}>
                      {" "}
                      <span style={{ color: "#FFDB7E" }}> Provide</span> Your
                      Recruitment Needs{" "}
                    </span>
                  </div>

                  <div style={{ padding: "1rem" }}>
                    <form
                      onSubmit={handleformsubmitfirst}
                      style={{ padding: isXs ? "0px 10px" : "0px 0px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "start",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          School / Institute Basic Information
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: isXs ? "20px" : "7px",
                          }}
                        >
                          <TextField
                            name="fullname"
                            label="Full Name"
                            type="text"
                            placeholder="Enter your Full Name"
                            required
                            onChange={(e) => setName(e.target.value)}
                            inputProps={{
                              style: {
                                height: "10px",
                                fontSize: "12px",
                                width: "14.7rem",
                              },
                            }}
                            InputLabelProps={{ sx: { color: "black" } }}
                            color="grey"
                            focused
                          />
                          <TextField
                            name="institutionname"
                            label="School / Institute Type"
                            type="text"
                            placeholder="Enter your Institution Name"
                            required
                            onChange={(e) => setInstitutename(e.target.value)}
                            inputProps={{
                              style: {
                                height: "10px",
                                fontSize: "12px",
                                width: "14.7rem",
                              },
                            }}
                            InputLabelProps={{ sx: { color: "black" } }}
                            color="grey"
                            focused
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: isXs ? "20px" : "7px",
                          }}
                        >
                          <TextField
                            name="emailaddress"
                            label="Email Address"
                            type="email"
                            placeholder="Enter Email Address"
                            required
                            onChange={(e) => setEmailaddress(e.target.value)}
                            inputProps={{
                              style: {
                                height: "10px",
                                fontSize: "12px",
                                width: "14.7rem",
                              },
                            }}
                            InputLabelProps={{ sx: { color: "black" } }}
                            color="grey"
                            focused
                          />
                          <TextField
                            name="phonenumber"
                            label="Phone Number"
                            type="number"
                            value={phoneNumber}
                            placeholder="Enter Phone Number"
                            required
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                return setPhoneNumber(value);
                              }
                            }}
                            inputProps={{
                              style: {
                                height: "10px",
                                fontSize: "12px",
                                width: "14.7rem",
                              },
                            }}
                            InputLabelProps={{ sx: { color: "black" } }}
                            color="grey"
                            focused
                          />
                        </div>

                        <TextField
                          name="address"
                          label="Address"
                          type="text"
                          placeholder="Enter Your Address"
                          required
                          onChange={(e) => setAddress(e.target.value)}
                          inputProps={{
                            style: {
                              height: "10px",
                              fontSize: "12px",
                              width: "32.5rem",
                            },
                          }}
                          InputLabelProps={{ sx: { color: "black" } }}
                          color="grey"
                          focused
                        />

                        <div
                          style={{
                            display: "flex",
                            gap: isXs ? "20px" : "7px",
                          }}
                        >
                          <TextField
                            name="pincode"
                            label="Pin Code"
                            type="number"
                            value={pinCode}
                            placeholder="Pin Code"
                            required
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,6}$/.test(value)) {
                                return setPinCode(value);
                              }
                            }}
                            inputProps={{
                              style: {
                                height: "10px",
                                fontSize: "12px",
                                width: "7rem",
                              },
                            }}
                            InputLabelProps={{ sx: { color: "black" } }}
                            color="grey"
                            focused
                          />
                          <TextField
                            name="city"
                            label="City"
                            type="text"
                            placeholder="City"
                            required
                            onChange={(e) => setCityName(e.target.value)}
                            inputProps={{
                              style: {
                                height: "10px",
                                fontSize: "12px",
                                width: "10rem",
                              },
                            }}
                            InputLabelProps={{ sx: { color: "black" } }}
                            color="grey"
                            focused
                          />
                          <TextField
                            name="state"
                            label="State"
                            type="text"
                            placeholder="State"
                            required
                            onChange={(e) => setStateName(e.target.value)}
                            inputProps={{
                              style: {
                                height: "10px",
                                fontSize: "12px",
                                width: "10rem",
                              },
                            }}
                            InputLabelProps={{ sx: { color: "black" } }}
                            color="grey"
                            focused
                          />
                        </div>
                        <div style={{ fontSize: "16px", fontWeight: "500" }}>
                          Post a New Job
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            flexDirection: isXs ? "" : "column",
                          }}
                        >
                          <TextField
                            name="jobtitle"
                            label="Job Title"
                            type="text"
                            placeholder="Example: English Teacher"
                            required
                            onChange={(e) => setJobTitle(e.target.value)}
                            inputProps={{
                              style: {
                                height: "10px",
                                fontSize: "12px",
                                width: "10rem",
                              },
                            }}
                            InputLabelProps={{ sx: { color: "black" } }}
                            color="grey"
                            focused
                          />
                          <FormControl
                            required
                            sx={{ minWidth: 140, maxHeight: 20 }}
                          >
                            <InputLabel
                              style={{ fontSize: "12px" }}
                              id="job-type-label"
                            >
                              Job Type
                            </InputLabel>
                            <Select
                              labelId="job-type-label"
                              id="job-type-select"
                              name="jobtype"
                              value={jobType}
                              style={{
                                height: "45px",
                                fontSize: "12px",
                                border: "1.5px solid #C4C4C4",
                              }}
                              label="Job Type"
                              focused
                              onChange={handleJobType}
                            >
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value="Full Time"
                              >
                                Full Time
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value="Part Time"
                              >
                                Part Time
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value="Online Teaching"
                              >
                                Online Teaching
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            name="institutetype"
                            label="School / Institute Type"
                            type="text"
                            style={{ marginTop: isXs ? "" : "20px" }}
                            placeholder="Ex. CBSE Board School"
                            required
                            onChange={(e) => setInstituteType(e.target.value)}
                            inputProps={{
                              style: {
                                height: "10px",
                                fontSize: "12px",
                                width: "10rem",
                              },
                            }}
                            InputLabelProps={{ sx: { color: "black" } }}
                            color="grey"
                            focused
                          />
                        </div>
                        <TextareaAutosize
                          name="jobdescription"
                          aria-label="Job Description"
                          placeholder="Description"
                          required
                          focused
                          onChange={(e) => setJobDescription(e.target.value)}
                          style={{
                            height: isXs ? "25px" : "27px",
                            fontSize: "12px",
                            width: isXs ? "33rem" : "100%",
                            border: "2px solid #C4C4C4",
                            borderRadius: "4px",
                            padding: "8px",
                            outline: "none",
                          }}
                        />

                        <div
                          style={{
                            display: "flex",
                            gap: isXs ? "20px" : "7px",
                          }}
                        >
                          <FormControl
                            required
                            sx={{
                              minWidth: isXs ? "180px" : "100px",
                              maxHeight: 20,
                            }}
                          >
                            <InputLabel
                              style={{ fontSize: "12px" }}
                              id="salary-label"
                            >
                              Salary
                            </InputLabel>
                            <Select
                              name="salary"
                              labelId="salary-label"
                              id="salary-select"
                              value={salary}
                              style={{
                                height: "45px",
                                fontSize: "12px",
                                border: "1.5px solid #C4C4C4",
                              }}
                              label="Salary"
                              focused
                              onChange={handlesalary}
                            >
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value="Year"
                              >
                                Year
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value="Month"
                              >
                                Month
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value="Week"
                              >
                                Week
                              </MenuItem>
                              <MenuItem
                                style={{ fontSize: "12px" }}
                                value="Hour"
                              >
                                Hour
                              </MenuItem>
                            </Select>
                          </FormControl>

                          <TextField
                            name="minsalary"
                            label="Min"
                            value={min}
                            type="number"
                            placeholder="₹"
                            required
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,11}$/.test(value)) {
                                return setMin(value);
                              }
                            }}
                            inputProps={{
                              style: {
                                height: "10px",
                                fontSize: "12px",
                                width: "8rem",
                                appearance: "none",
                              },
                            }}
                            InputLabelProps={{ sx: { color: "black" } }}
                            color="grey"
                            focused
                          />

                          <TextField
                            name="max"
                            label="Max"
                            value={max}
                            type="number"
                            placeholder="₹"
                            required
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,11}$/.test(value)) {
                                return setMax(value);
                              }
                            }}
                            inputProps={{
                              style: {
                                height: "10px",
                                fontSize: "12px",
                                width: "8rem",
                                appearance: "none",
                              },
                            }}
                            InputLabelProps={{ sx: { color: "black" } }}
                            color="grey"
                            focused
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "10vh",
                          }}
                        >
                          {loading ? (
                            <Stack
                              sx={{ color: "grey.500" }}
                              spacing={2}
                              direction="row"
                            >
                              <CircularProgress color="success" />
                            </Stack>
                          ) : (
                            <button
                              type="submit"
                              style={{
                                backgroundColor: "#CB8B65",
                                color: "white",
                                width: isXs ? "33rem" : "100%",
                                textAlign: isXs ? "center" : "center",
                                height: "2.5rem",
                                border: "none",
                                borderRadius: "3px",
                              }}
                            >
                              {" "}
                              Next
                            </button>
                          )}
                        </div>

                      </div>
                    </form>
                  </div>
                </Dialog>

                <Dialog
                  open={openOtp}
                  onClose={() => { }}
                  maxWidth="md"
                  sx={{
                    "& .MuiBackdrop-root": {
                      backdropFilter: "blur(1px)", // Apply blur effect directly
                    },
                  }}
                  PaperProps={{
                    sx: {
                      padding: "rem",
                      overflowX: "hidden",
                    },
                  }}
                >
                  <Button
                    onClick={handleCloseOtp}
                    style={{
                      position: "absolute",
                      right: "0%",
                      top: "0",
                      backgroundColor: "white",
                      borderRadius: "100%",
                      minWidth: "2rem",
                      color: "black",
                      border: "1px solid black",
                    }}
                  >
                    X
                  </Button>

                  <div style={{ padding: "1rem" }}>
                    <form
                      onSubmit={handleSubmitOtp}
                      style={{ padding: isXs ? "0px 10px" : "0px 0px" }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ fontSize: "30px", textAlign: "center" }}>
                          OTP Verification{" "}
                        </div>

                        <div style={{ textAlign: "center" }}>
                          {" "}
                          A One-Time Password has been sent to{" "}
                          {formNew.map((item) => (
                            <span key={item.smsID}>{item.smsMobile}</span>
                          ))}
                        </div>
                        <div>
                          <MuiOtpInput
                            style={{
                              width: isXs ? "400px" : "270px",
                              gap: isXs ? "20px" : "5px",
                              padding: "14px 3px",
                              alignItems: "center",
                              textAlign: "center",
                              display: "flex",
                              margin: "0px auto",
                            }}
                            value={otp}
                            onChange={handleChangeOtp}
                            length={6}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px",
                            gap: "10px",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ textAlign: isXs ? "center" : "left" }}>
                            Didn’t Receive OTP?
                          </div>
                          {loading2 ? (
                            <Stack
                              sx={{ color: "grey.500" }}
                              spacing={2}
                              direction="row"
                            >
                              <CircularProgress color="success" />
                            </Stack>
                          ) : (
                            <div
                              type="button"
                              style={{
                                color: "#cb8b65",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                              onClick={handleResendOtp}
                            >
                              Resend OTP
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          marginTop: "70px",
                          flexDirection: "column",
                        }}
                      >
                        {loading ? (
                          <Stack
                            sx={{ color: "grey.500" }}
                            spacing={2}
                            direction="row"
                          >
                            <CircularProgress color="success" />
                          </Stack>
                        ) : (
                          <button
                            style={{
                              backgroundColor: "#CB8B65",
                              color: "white",
                              width: isXs ? "30rem" : "20rem",
                              height: "2.5rem",
                              border: "none",
                              borderRadius: "3px",
                            }}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </Dialog>

                <Dialog
                  open={openOtpNew}
                  onClose={() => { }}
                  maxWidth="md"
                  sx={{
                    "& .MuiBackdrop-root": {
                      backdropFilter: "blur(1px)", // Apply blur effect directly
                    },
                  }}
                  PaperProps={{
                    sx: {
                      padding: "rem",
                      overflowX: "hidden",
                    },
                  }}
                >
                  <Button
                    onClick={handleCloseOtpNew}
                    style={{
                      position: "absolute",
                      right: "0%",
                      top: "0",
                      backgroundColor: "white",
                      borderRadius: "100%",
                      minWidth: "2rem",
                      color: "black",
                      border: "1px solid black",
                    }}
                  >
                    X
                  </Button>

                  <div style={{ padding: "1rem" }}>
                    <form
                      onSubmit={handleSubmitOtpNew}
                      style={{ padding: isXs ? "0px 10px" : "0px 0px" }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ fontSize: "30px", textAlign: "center" }}>
                          OTP Verification{" "}
                        </div>

                        <div style={{ textAlign: "center" }}>
                          {" "}
                          A One-Time Password has been sent to{" "}
                          {studentQueryFormData
                            ? studentQueryFormData.get("mobile")
                            : null}
                        </div>
                        <div>
                          <MuiOtpInput
                            style={{
                              width: isXs ? "400px" : "270px",
                              gap: isXs ? "20px" : "5px",
                              padding: "14px 3px",
                              alignItems: "center",
                              textAlign: "center",
                              display: "flex",
                              margin: "0px auto",
                            }}
                            value={otpNew}
                            onChange={handleChangeOtpNew}
                            length={6}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px",
                            gap: "10px",
                            flexDirection: isXs ? "" : "column",
                          }}
                        >
                          <div style={{ textAlign: "center" }}>
                            Didn’t Receive OTP?
                          </div>
                          {loading2 ? (
                            <Stack
                              sx={{ color: "grey.500" }}
                              spacing={2}
                              direction="row"
                            >
                              <CircularProgress color="success" />
                            </Stack>
                          ) : (
                            <div
                              type="button"
                              style={{ color: "#cb8b65", textAlign: "center" }}
                              onClick={handleResendOtpNew}
                            >
                              Resend OTP
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "70px",
                        }}
                      >
                        {loading ? (
                          <Stack
                            sx={{ color: "grey.500" }}
                            spacing={2}
                            direction="row"
                          >
                            <CircularProgress color="success" />
                          </Stack>
                        ) : (
                          <button
                            style={{
                              backgroundColor: "#CB8B65",
                              color: "white",
                              width: "30rem",
                              height: "2.5rem",
                              border: "none",
                              borderRadius: "3px",
                            }}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </Dialog>

                <img
                  src={Group35}
                  style={{
                    position: "absolute",
                    bottom: "0%",
                    left: "5%",
                    display: isXs ? "none" : "",
                  }}
                />
                <img
                  src={Group36}
                  style={{
                    position: "absolute",
                    bottom: "0%",
                    right: "5%",
                    display: isXs ? "none" : "",
                  }}
                />

              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* Section3 */}
      <Grid container sx={sxStyle} style={{ marginTop: isXs ? "10%" : "10%" }}>
        <Grid item xs={10} sm={5} md={6} lg={6} xl={6} xxl={6}>
          <div
            style={{
              marginLeft: isXs ? "5%" : "",
              textAlign: isXs ? "" : "center",
            }}
          >
            <h2 style={{ fontWeight: "bold" }}>
              Experienced Tutors for Personal Tuitions
            </h2>
            <div
              style={{
                color: "#CB8B65",
                marginTop: "3%",
                fontSize: isXs ? "1.5vw" : "4vw",
              }}
            >
              Your Gateway to Personalized Education
            </div>
            <div
              style={{
                marginTop: isXs ? "1%" : "10%",
                fontSize: isXs ? "1.1vw" : "2.5",
                textAlign: "justify",
              }}
            >
              Unlock Your Child's Potential with Kanha Home Tutions! Our experienced tutors provide personalized education and best home tuitions to help your child excel in their studies. We offer subject-wise expertise and tuition for CBSE, IIT- JEE, NEET,NDA and other boards. Our services are flexible and budget-friendly, with online and offline classes available. Find the best home tutor for your child with us and ensure their success in competitive exams like IIT-JEE, NEET, Olympiad , BITSAT,AIIMS ,KVPY 12th,11th ( CBSE, ICSE,IB,& State Boards )With Kanha Home Tutions, your child's future can shine bright!
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={10}
          sm={5}
          md={6}
          lg={6}
          xl={6}
          xxl={6}
          style={{ textAlign: "center" }}
        >
          <img
            src={Group2111}
            style={{
              width: isXs ? "70%" : "95%",
              marginTop: isXs ? "10%" : "15%",
            }}
          />
        </Grid>
      </Grid>

      {/* Section4 */}


      <div style={{ marginTop: '90px', height: isXs ? '650px' : '740px', width: '100%', backgroundColor: '#F6EBDC', display: 'flex', flexDirection: isXs ? 'row' : 'column', padding: isXs ? "0px 70px" : "50px 20px", gap: '20px', alignItems: 'center' }}>

        <div style={{ width: '40%', display: isXs ? 'flex' : 'none', }}>
          <div style={{ width: '400px', height: isXs ? '400px' : '200px', backgroundColor: 'white', borderRadius: '20px 30px' }}><img style={{ height: '100%', width: '100%' }} src={teacherImg} alt="" /></div>
        </div>
        <div style={{ width: isXs ? '60%' : '100%', gap: '20px', display: 'flex', flexDirection: 'column' }}>

          <div style={{ fontSize: '36px', fontWeight: '600', lineHeight: '45px' }}>Post Your School & Institute <br /> Vacancies - Find Qualified Teachers</div>
          <div style={{ fontSize: '16px', fontWeight: '500' }}>Connect with qualified teachers and post your school's vacancies, including teaching jobs, administrative positions, and more. Get noticed by top talent and find the right fit for your institution.</div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '10px' }}>
                <div style={{ width: '20px', height: '20px' }}><img style={{ height: '100%', width: '100%' }} src={CheckImg} alt="" /></div>
                <div style={{ fontSize: '14px' }}> <span style={{ fontWeight: '500', fontSize: '15px' }}>Access to Top Talent:</span>  Get applications from highly skilled and experienced teachers.</div>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <div style={{ width: '20px', height: '20px' }}><img style={{ height: '100%', width: '100%' }} src={CheckImg} alt="" /></div>
                <div style={{ fontSize: '14px' }}> <span style={{ fontWeight: '500', fontSize: '15px' }}>Verified Candidates:</span> Our screening process to ensure quality and reliability.</div>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <div style={{ width: '20px', height: '20px' }}><img style={{ height: '100%', width: '100%' }} src={CheckImg} alt="" /></div>
                <div style={{ fontSize: '14px' }}> <span style={{ fontWeight: '500', fontSize: '15px' }}>Global Reach:</span> Vast network of educators, locally and globally.</div>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <div style={{ width: '20px', height: '20px' }}><img style={{ height: '100%', width: '100%' }} src={CheckImg} alt="" /></div>
                <div style={{ fontSize: '14px' }}> <span style={{ fontWeight: '500', fontSize: '15px' }}>Save Time:</span>Let us help you find the right candidates quickly and efficiently.</div>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <div style={{ width: '20px', height: '20px' }}><img style={{ height: '100%', width: '100%' }} src={CheckImg} alt="" /></div>
                <div style={{ fontSize: '14px' }}> <span style={{ fontWeight: '500', fontSize: '15px' }}>Customized Salary Insights:</span> Get personalized salary estimates based on market trends.</div>
              </div>
            </div>
          </div>
          <div>
            <Link to="/teacher-job"
              className="py-2 px-2"
              style={{
                backgroundColor: "#FFDB7E",
                border: "none",
                width: "300px",
                color: "#866D2C",
                borderRadius: "5px",
                fontWeight: "bold",
                fontSize: isXs ? "1.5rem" : "1rem",
                height: isXs ? "4rem" : "3rem",
                boxShadow: "5px  5px 2px rgb(0,0,0, .2)",
                textDecoration: "none",
                display: 'flex', alignItems: 'center',
                textAlign: 'center', justifyContent: 'center'
              }}
            >
              <div style={{ color: "#866D2C", display: 'flex', }}>
                <div >Post a Teacher Job</div>
                <div> <img
                  src={DottedArrow}
                  style={{
                    width: "100%",
                    maxWidth: "25px",
                    marginLeft: "10px",
                  }}
                /></div>

              </div>
            </Link>
          </div>
        </div>
      </div>

      <div style={{ height: isXs ? '600px' : '1160px', padding: '70px 0px 90px  0px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ textAlign: 'center', color: "#A6653F", fontSize: isXs ? "36px" : "6vw", fontWeight: "bold", marginBottom: '50px' }}>Home Tutoring - in your City</div>
        <div style={{ display: 'grid', gridTemplateColumns: isXs ? 'repeat(5, 1fr)' : 'repeat(2, 1fr)', justifyContent: 'center', gap: '20px' }}>

          <div onClick={handleOpen} style={{ height: '170px', width: '180px', borderRadius: '25px', backgroundColor: '#f7ebdd', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ height: '80px', width: '80px' }}>
              <img src={Delhi} alt="Delhi" style={{ width: '100%', height: '100%', objectFit: 'contain ' }} />
            </div>
            <div style={{ textAlign: 'center', fontSize: "18px", marginTop: "15px" }}>Delhi</div>
          </div>

          <div onClick={handleOpen} style={{ height: '170px', width: '180px', borderRadius: '25px', backgroundColor: '#f7ebdd', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ height: '80px', width: '80px' }}>
              <img src={mumbai} alt="Mumbai" style={{ width: '100%', height: '100%', objectFit: 'contain ' }} />
            </div>
            <div style={{ textAlign: 'center', fontSize: "18px", marginTop: "15px" }}>Mumbai</div>
          </div>

          <div onClick={handleOpen} style={{ height: '170px', width: '180px', borderRadius: '25px', backgroundColor: '#f7ebdd', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ height: '80px', width: '80px' }}>
              <img src={Bengaluru} alt="Bengaluru" style={{ width: '100%', height: '100%', objectFit: 'contain ' }} />
            </div>
            <div style={{ textAlign: 'center', fontSize: "18px", marginTop: "15px" }}>Bengaluru</div>
          </div>

          <div onClick={handleOpen} style={{ height: '170px', width: '180px', borderRadius: '25px', backgroundColor: '#f7ebdd', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ height: '80px', width: '80px' }}>
              <img src={Chennai} alt="Chennai" style={{ width: '100%', height: '100%', objectFit: 'contain ' }} />
            </div>
            <div style={{ textAlign: 'center', fontSize: "18px", marginTop: "15px" }}>Chennai</div>
          </div>

          <div onClick={handleOpen} style={{ height: '170px', width: '180px', borderRadius: '25px', backgroundColor: '#f7ebdd', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ height: '80px', width: '80px' }}>
              <img src={Kolkata} alt="Kolkata" style={{ width: '100%', height: '100%', objectFit: 'contain ' }} />
            </div>
            <div style={{ textAlign: 'center', fontSize: "18px", marginTop: "15px" }}>Kolkata</div>
          </div>

          <div onClick={handleOpen} style={{ height: '170px', width: '180px', borderRadius: '25px', backgroundColor: '#f7ebdd', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ height: '80px', width: '80px' }}>
              <img src={hyderabad} alt="Hyderabad" style={{ width: '100%', height: '100%', objectFit: 'contain ' }} />
            </div>
            <div style={{ textAlign: 'center', fontSize: "18px", marginTop: "15px" }}>Hyderabad</div>
          </div>

          <div onClick={handleOpen} style={{ height: '170px', width: '180px', borderRadius: '25px', backgroundColor: '#f7ebdd', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ height: '80px', width: '80px' }}>
              <img src={Pune} alt="Pune" style={{ width: '100%', height: '100%', objectFit: 'contain ' }} />
            </div>
            <div style={{ textAlign: 'center', fontSize: "18px", marginTop: "15px" }}>Pune</div>
          </div>

          <div onClick={handleOpen} style={{ height: '170px', width: '180px', borderRadius: '25px', backgroundColor: '#f7ebdd', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ height: '80px', width: '80px' }}>
              <img src={Ahmedabad} alt="Ahmedabad" style={{ width: '100%', height: '100%', objectFit: 'contain ' }} />
            </div>
            <div style={{ textAlign: 'center', fontSize: "18px", marginTop: "15px" }}>Ahmedabad</div>
          </div>

          <div onClick={handleOpen} style={{ height: '170px', width: '180px', borderRadius: '25px', backgroundColor: '#f7ebdd', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ height: '80px', width: '80px' }}>
              <img src={jaipur} alt="Jaipur" style={{ width: '100%', height: '100%', objectFit: 'contain ' }} />
            </div>
            <div style={{ textAlign: 'center', fontSize: "18px", marginTop: "15px" }}>Jaipur</div>
          </div>

          <div onClick={handleOpen} style={{ height: '170px', width: '180px', borderRadius: '25px', backgroundColor: '#f7ebdd', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ height: '80px', width: '80px' }}>
              <img src={Lucknow} alt="Lucknow" style={{ width: '100%', height: '100%', objectFit: 'contain ' }} />
            </div>
            <div style={{ textAlign: 'center', fontSize: "18px", marginTop: "15px" }}>Lucknow</div>
          </div>

        </div>
      </div>

      <div style={{ height: isXs ? '200px' : '220px', padding: isXs ? '0px 90px' : '0px 20px', backgroundColor: '#F8E7C9', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <div style={{ width: isXs ? '20%' : '0%', display: 'flex', justifyContent: 'center', display: isXs ? 'flex' : 'none' }}>
          <div style={{ height: '140px', width: isXs ? '140px' : '150px', backgroundColor: 'white', borderRadius: '20px', }}>
            <img src={kathmandu} alt="Lucknow" style={{ width: '100%', height: '100%', objectFit: 'contain ' }} />
          </div>
        </div>

        <div style={{ width: isXs ? '70%' : '100%', display: "flex", flexDirection: 'column', gap: isXs ? '20px' : '30px' }}>
          <div style={{ fontSize: isXs ? '32px' : '22px', fontWeight: '500', }}> Your Learning Journey <br style={{ display: isXs ? 'none' : 'flex' }} /> Starts Here – Kathmandu</div>
          <div style={{}}>
            <a style={{ backgroundColor: '#CB8B65', borderRadius: '5px', padding: '8px 20px', textDecoration: 'none', color: 'white', fontSize: isXs ? '20px' : '16px', cursor: 'pointer', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' }} href="online-coaching-in-kathmandu">Online Coaching in Kathmandu</a>
          </div>
        </div>

      </div>



      <Grid
        container
        sx={{
          backgroundColor: "#CB8B65",
          display: "flex",
          marginTop: isXs ? "0%" : "0",
        }}
      >
        <div
          className="py-4 d-flex justify-content-center"
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "28px",
            width: "100%",
            marginTop: isXs ? "3%" : "12%",
            margin: "0 auto",
          }}
        >
          Why Choose Our Tutors?
        </div>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: isXs ? "space-evenly" : "center",
            alignItems: isXs ? "" : "center",
            marginTop: isXs ? "1%" : "20%",
          }}
        >
          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{ position: "relative" }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Proven Expertise:
              </div>
              <div>
                Each tutor is rigorously selected based on their knowledge,
                teaching experience, and ability to adapt to different learning
                styles and needs.
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin1}
                  style={{
                    position: "absolute",
                    bottom: "-10%",
                    left: "8%",
                  }}
                />
              </div>
            </div>

            <div className="text-end" style={{ marginTop: "30%" }}>
              <img src={Scale} style={{ display: isXs ? "none" : "none" }} />
            </div>
            <img
              src={Scale}
              style={{
                position: "absolute",
                zIndex: 2,
                left: "-3rem",
                bottom: "1rem",
                width: "30%",
                display: isXs ? "none" : "",
              }}
            />
          </Grid>

          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{ marginTop: "5%" }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Pin2}
                  style={{
                    position: "absolute",
                    top: "-12%",
                    left: "8%",
                  }}
                />
              </div>
              <div>
                <img
                  src={Leaderstyle2}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-18%",
                    right: "36%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Customized Approach:
              </div>
              <div style={{ marginBottom: "15%" }}>
                Our tutors understand that each student is unique. They
                customize their teaching methods to match individual learning
                paces and preferences, ensuring optimal comprehension and
                retention.
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{
              position: "relative",
              marginTop: isXs ? "" : "30%",
              marginBottom: isXs ? "" : "20%",
            }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle3}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                    right: "8%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Results-Oriented:
              </div>
              <div>
                Focused on achieving tangible academic improvements, our tutors
                are committed to helping each student reach and surpass their
                academic goals.
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin3}
                  style={{
                    position: "absolute",
                    bottom: "-9%",
                    right: "8%",
                  }}
                />
              </div>
            </div>
            <div
              className="col-lg-12 d-flex justify-content-end"
              style={{ backgroundColor: "#CB8B65" }}
            >
              {/* <img
                src={Frame}
                style={{
                  width: "40%",
                  position: "absolute",
                  right: "-25%",
                  marginTop: "30%",
                  display: isXs ? "" : "none",
                }}
              /> */}
              {/* <img
                src={Frame}
                style={{
                  position: "absolute",
                  zIndex: 2,
                  right: "-2rem",
                  width: "30%",
                  display: isXs ? "none" : "",
                }}
              /> */}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: isXs ? "space-evenly" : "center",
            alignItems: isXs ? "" : "center",
            marginTop: isXs ? "1%" : "10%",
          }}
        >
          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{ position: "relative" }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle4}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Best Home Tutors:
              </div>
              <div>
                We provide the best home tutors in India, ensuring that students receive the highest quality education.
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin1}
                  style={{
                    position: "absolute",
                    bottom: "-10%",
                    left: "8%",
                  }}
                />
              </div>
            </div>

            <div className="text-end" style={{ marginTop: "30%" }}>
              <img src={Scale} style={{ display: isXs ? "" : "none" }} />
            </div>
            <img
              src={Scale}
              style={{
                position: "absolute",
                zIndex: 2,
                left: "-3rem",
                bottom: "1rem",
                width: "30%",
                display: isXs ? "none" : "",
              }}
            />
          </Grid>

          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{ marginTop: "5%" }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Pin2}
                  style={{
                    position: "absolute",
                    top: "-12%",
                    left: "8%",
                  }}
                />
              </div>
              <div>
                <img
                  src={Leaderstyle5}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-18%",
                    right: "36%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Expert Coaching:
              </div>
              <div style={{ marginBottom: "15%" }}>
                IIT-JEE, NEET & BITSAT Coaching: Our teachers have extensive experience in coaching students for IIT-JEE, NEET & BITSAT with a deep understanding of the exam pattern and syllabus.
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            sx={{
              position: "relative",
              marginTop: isXs ? "" : "30%",
              marginBottom: isXs ? "" : "20%",
            }}
          >
            <div
              className="rounded-5"
              style={{
                backgroundColor: "white",
                padding: "20px",
                position: "relative",
              }}
            >
              <div>
                <img
                  src={Leaderstyle6}
                  style={{
                    width: "30%",
                    position: "absolute",
                    top: "-20%",
                    right: "8%",
                  }}
                />
              </div>
              <div className="fw-bolder fs-5 py-2" style={{ marginTop: "15%" }}>
                Excellence:
              </div>
              <div>
                11th and 12th Class Tuitions: Our teachers are experts in their respective subjects and have a proven track record of helping students achieve high grades in their 11th and 12th class ( CBSE, ICSE,IB,& State Boards) exams.
              </div>
              <div style={{ marginBottom: "15%" }}>
                <img
                  src={Pin3}
                  style={{
                    position: "absolute",
                    bottom: "-9%",
                    right: "8%",
                  }}
                />
              </div>
            </div>
            <div
              className="col-lg-12 d-flex justify-content-end"
              style={{ backgroundColor: "#CB8B65" }}
            >
              <img
                src={Frame}
                style={{
                  width: "40%",
                  position: "absolute",
                  right: "-25%",
                  marginTop: "30%",
                  display: isXs ? "" : "none",
                }}
              />
              <img
                src={Frame}
                style={{
                  position: "absolute",
                  zIndex: 2,
                  right: "-2rem",
                  width: "30%",
                  display: isXs ? "none" : "",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      {/* Section5 */}
      <Grid container sx={sxStyle}>
        <div
          className="text-center"
          style={{
            marginTop: isXs ? "5%" : "10%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: isXs ? "1.6vw" : "4.5vw",
              fontWeight: "bold",
              width: isXs ? "60%" : "70%",
            }}
          >
            How Our Kanha Home Tutions Service Simplifies Your Search for the
            Perfect Tutor
          </div>
          <img
            src={line3}
            style={{
              width: "30%",
              maxWidth: "200px",
              margin: "auto",
              marginTop: isXs ? "1rem" : "2rem",
            }}
          />
        </div>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={sxStyle}
        >
          <div
            style={{
              marginTop: isXs ? "5%" : "10%",
              backgroundColor: "#F6EBDC",
              width: isXs ? "65%" : "80%",
              height: isXs ? "17.2rem" : "40rem",
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : "200px",
              borderBottomLeftRadius: isXs ? "500px" : "",
              borderBottomRightRadius: isXs ? "500px" : "",
              borderTopRightRadius: isXs ? "" : "200px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                sx={{ margin: isXs ? "" : "0 auto" }}
              >
                <img
                  src={Step1}
                  alt="Step1"
                  style={{
                    width: isXs ? "94%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ marginRight: isXs ? "8%" : "0 auto" }}>
                  <div
                    className="d-flex fw-bold"
                    style={{
                      fontSize: isXs ? "2vw" : "5vw",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Grid
                      container
                      justifyContent={isXs ? "flex-start" : "center"}
                      sx={{ gap: isXs ? "0.5rem" : "" }}
                    >
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2} xxl={2}>
                        <div
                          style={{
                            color: "#CB8B65",
                            textAlign: isXs ? "" : "center",
                            marginTop: isXs ? "" : "11%",
                          }}
                        >
                          Step 1
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        xxl={5}
                        sx={{ right: "5%" }}
                      >
                        <div style={{ textAlign: isXs ? "" : "center" }}>
                          Sign Up Easily
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      fontSize: isXs ? "1vw" : "4vw",
                      textAlign: isXs ? "" : "center",
                      width: isXs ? "" : "80%",
                      margin: isXs ? "" : "0 auto",
                      marginTop: isXs ? "2%" : "8%",
                    }}
                  >
                    Start by registering on the Kanha Home Tuitions website or
                    app, or simply contact us directly. This step allows you to
                    clarify your specific tutoring needs and preferences,
                    ensuring we connect you with the ideal candidates.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={sxStyle}
          style={{ display: isXs ? "" : "none" }}
        >
          <div
            className="d-flex align-items-center py-3"
            style={{
              marginTop: "5%",
              backgroundColor: "#CB8B65",
              width: isXs ? "65%" : "80%",
              height: isXs ? "17.2rem" : "30rem",
              position: "relative",
              borderTopLeftRadius: isXs ? "" : "200px",
              borderBottomLeftRadius: isXs ? "500px" : "",
              borderBottomRightRadius: isXs ? "500px" : "",
              borderTopRightRadius: isXs ? "500px" : "200px",
            }}
          >
            <Grid
              container
              sx={{ display: "flex", justifyContent: isXs ? "flex-end" : "" }}
            >
              <Grid
                item
                xs={10}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <div
                    className="d-flex fw-bold"
                    style={{ fontSize: "2vw", gap: "1rem" }}
                  >
                    <div style={{ color: "#FFDB7E", width: "100px" }}>
                      Step 2
                    </div>
                    <div style={{ color: "white", width: "80%" }}>
                      Post Tutoring Requirements
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "1vw",
                      color: "white",
                      width: "90%",
                      marginTop: isXs ? "2%" : "",
                    }}
                  >
                    Create a detailed post outlining your child’s academic
                    needs, preferred schedule, and any special considerations.
                    You can also browse posts from our extensive network of
                    qualified tutors to find the right match.
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={10}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <img
                  src={Step2}
                  alt="Step2"
                  style={{
                    width: isXs ? "95%" : "120%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={sxStyle}
          style={{ display: isXs ? "none" : "", marginTop: "5%" }}
        >
          <div
            style={{
              marginTop: "5%",
              backgroundColor: "#CB8B65",
              width: isXs ? "65%" : "80%",
              height: isXs ? "17.2rem" : "40rem",
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : "200px",
              borderBottomLeftRadius: isXs ? "500px" : "",
              borderBottomRightRadius: isXs ? "500px" : "",
              borderTopRightRadius: isXs ? "" : "200px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                sx={{ margin: isXs ? "" : "0 auto" }}
              >
                <img
                  src={Step2}
                  alt="Step2"
                  style={{
                    width: isXs ? "93%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ margin: isXs ? "" : "0 auto" }}>
                  <div
                    className="d-flex fw-bold"
                    style={{
                      fontSize: isXs ? "2vw" : "5vw",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Grid
                      container
                      justifyContent={isXs ? "flex-start" : "center"}
                    >
                      <Grid
                        item
                        xs={10}
                        sm={2.5}
                        md={2.5}
                        lg={2.5}
                        xl={2.5}
                        xxl={2.5}
                      >
                        <div
                          style={{
                            color: "#FFDB7E",
                            marginRight: "0.5rem",
                            textAlign: isXs ? "" : "center",
                            marginTop: isXs ? "" : "11%",
                          }}
                        >
                          Step 2
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        xxl={5}
                        sx={{ right: "5%" }}
                      >
                        <div
                          style={{
                            textAlign: isXs ? "" : "center",
                            color: "white",
                          }}
                        >
                          Post Tutoring Requirements
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      fontSize: isXs ? "1vw" : "4vw",
                      color: "white",
                      textAlign: isXs ? "" : "center",
                      width: isXs ? "" : "80%",
                      margin: isXs ? "" : "0 auto",
                      marginTop: isXs ? "" : "8%",
                    }}
                  >
                    Create a detailed post outlining your child’s academic
                    needs, preferred schedule, and any special considerations.
                    You can also browse posts from our extensive network of
                    qualified tutors to find the right match.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={sxStyle}
          style={{ marginTop: "5%" }}
        >
          <div
            style={{
              marginTop: "0.2%",
              backgroundColor: "#F6EBDC",
              width: isXs ? "65%" : "80%",
              height: isXs ? "17.2rem" : "42rem",
              position: "relative",
              borderTopLeftRadius: isXs ? "500px" : "200px",
              borderBottomLeftRadius: isXs ? "500px" : "",
              borderBottomRightRadius: isXs ? "500px" : "",
              borderTopRightRadius: isXs ? "" : "200px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                sx={{ margin: isXs ? "" : "0 auto" }}
              >
                <img
                  src={Step3}
                  alt="Step3"
                  style={{
                    width: isXs ? "94%" : "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    borderRadius: "50%",
                    marginTop: isXs ? "" : "11%",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ margin: isXs ? "" : "0 auto" }}>
                  <div
                    className="d-flex fw-bold"
                    style={{
                      fontSize: isXs ? "2vw" : "5vw",
                      justifyContent: isXs ? "" : "center",
                    }}
                  >
                    <Grid
                      container
                      justifyContent={isXs ? "flex-start" : "center"}
                      sx={{ gap: isXs ? "0.5rem" : "" }}
                    >
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2} xxl={2}>
                        <div
                          style={{
                            color: "#CB8B65",
                            textAlign: isXs ? "" : "center",
                            marginTop: isXs ? "" : "11%",
                          }}
                        >
                          Step 3
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        sx={{ right: "5%" }}
                      >
                        <div style={{ textAlign: isXs ? "" : "center" }}>
                          Choose the Ideal Tutor
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      fontSize: isXs ? "1vw" : "4vw",
                      textAlign: isXs ? "" : "center",
                      width: isXs ? "95%" : "80%",
                      margin: isXs ? "" : "0 auto",
                      marginTop: isXs ? "2%" : "8%",
                    }}
                  >
                    After posting your requirements, you'll receive responses
                    from our vetted tutors. Arrange a demo class to assess the
                    tutor’s compatibility with your child’s learning style and
                    needs. Once satisfied, you can hire the tutor and discuss
                    any further details directly.
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {/* Section6 */}
      <div style={{ marginTop: "8rem" }}>
        <img
          src={Frame24}
          alt="Frame24"
          style={{
            position: "absolute",
            width: "100%",
            display: isXs ? "" : "none",
            height: "auto",
          }}
        />
        <img
          src={Group221}
          alt="Group221"
          style={{
            position: "absolute",
            paddingTop: "4.2rem",
            left: "3.3rem",
            width: isXs ? "96%" : "0%",
          }}
        />
      </div>

      <div
        className="container-fluid"
        style={{
          position: "relative",
          overflow: "hidden",
          zIndex: 3,
          backgroundColor: isXs ? "" : "#CB8B65",
          padding: isXs ? "" : "3rem 0rem",
          height: isXs ? "auto" : "570px",
        }}
      >
        <div
          className="text-center"
          style={{
            marginTop: isXs ? "6%" : "1%",
            height: isXs ? "20rem" : "",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: isXs ? "2.2vw" : "6.6vw",
              fontWeight: "bold",
              width: isXs ? "55%" : "90%",
              color: "white",
            }}
          >
            Kickstart Your Child’s Academic Success Today!
          </div>
          <div
            style={{
              fontSize: isXs ? "1.2vw" : "4vw",
              width: isXs ? "70%" : "90%",
              color: "white",
              marginTop: isXs ? "3%" : "8%",
              textAlign: isXs ? "" : "center",
            }}
          >
            Forget the hassle of endless searching for 'tuition near me'. With
            Kanha Home Tutions, discover a personalized, efficient, and reliable
            approach to finding the perfect tutor for your child. Join our
            community of satisfied parents and witness your child's educational
            transformation.
          </div>
          <div
            className="d-flex justify-content-evenly"
            style={{
              width: isXs ? "60%" : "85%",

              marginTop: isXs ? "4%" : "10%",
              fontSize: "1.4vw",
            }}
          >
            <button
              className="py-2"
              type="button"
              onClick={handleOpen}
              style={{
                backgroundColor: "#FFDB7E",
                color: "#866D2C",
                border: "none",
                borderRadius: "3px",
                width: isXs ? "70%" : "20rem",
                height: isXs ? "4rem" : "5rem",
                fontWeight: "bold",
                fontSize: isXs ? "1.5rem" : "1.6rem",
                boxShadow: "5px  5px 2px rgb(0,0,0, .2)",
              }}
            >
              Post Your Requirments
            </button>

          </div>
          <img
            src={Globe}
            style={{
              position: "absolute",
              zIndex: 2,
              right: "-1rem",
              top: "1rem",
              width: "12%",
              display: isXs ? "none" : "",
            }}
          />
        </div>

        <img
          src={NoteBook}
          style={{
            position: "absolute",
            zIndex: 2,
            left: "3rem",
            bottom: "-1rem",
            width: "15%",
            display: isXs ? "none" : "",
          }}
        />
      </div>

      {/* 1 To 12 Classes Section*/}
      <div
        style={{
          height: isXs ? "800px" : "1150px",
          backgroundColor: "#F6EBDC",
          width: "100%",
          marginTop: isXs ? "2rem" : "0rem",
          marginBottom: isXs ? "0rem" : "7rem",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              fontSize: "28px",
              paddingTop: isXs ? "120px" : "50px",
              fontWeight: "bold",
            }}
          >
            Explore Categories
          </div>
        </div>
        <div
          style={{
            fontSize: "1.2rem",
            fontWeight: "bolder",
            marginTop: isXs ? "10px" : "20px",
            textAlign: isXs ? "center" : "center",
          }}
        >
          Tutors for home and online Tution
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isXs ? "repeat(5, 250px)" : "repeat(2, 1fr)",
            justifyItems: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: isXs ? "2rem 1rem" : "2rem 1rem",
            gap: isXs ? "" : "0.7rem",
            rowGap: isXs ? "50px" : "2rem",
          }}
        >
          {[
            { img: class1_6, label: "Class 1-6 Tution" },
            { img: class7, label: "Class 7 Tution" },
            { img: class8, label: "Class 8 Tution" },
            { img: class9, label: "Class 9 Tution" },
            { img: class10, label: "Class 10 Tution" },
            { img: class11, label: "Class 11 Tution" },
            { img: class12, label: "Class 12 Tution" },
            { img: IIT, label: "IIT-JEE Tution" },
            { img: Neet, label: "NEET Tution" },
            { img: NDA, label: "NDA Tution" },
          ].map((item, index) => (
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/student-signin"
              key={index}
            >
              <div
                key={index}
                style={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <div>
                  <img
                    src={item.img}
                    alt=""
                    style={{
                      objectFit: "cover",
                      borderRadius: "7px",
                      height: isXs ? "150px" : "120px",
                      width: "100%",
                      maxWidth: "220px",
                    }}
                  />
                </div>
                <div>{item.label}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>


      {/* Blog Section */}
      {blogBox.length >= 3 ? blogBox.slice(0, 1).map((item, index) => (
      <div style={{ textAlign: "center", fontSize: '36px', marginTop: '50px', fontWeight: 'bold' }}> Our Blogs</div>
    )) : ''}

      <div style={{ padding: '3rem 2rem 1rem 2rem', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', justifyItems: 'center', rowGap: '50px', gridAutoRows: 'auto' }}>
      {blogBox.length >= 3   ? blogBox.slice(0, 3).map((item, index) => (
  <div key={index} style={{ height: '450px', width: '359px', borderRadius: '20px', display: 'flex', flexDirection: 'column', gap: "10px", backgroundColor:'#ffffff' }}>
    <div style={{ height: '250px', width: '100%' }}>
      <img
        style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '20px', border:'1px solid lightgrey' }}
        src={item.postFile}
        alt={`blog-image-${index}`}
      />
    </div>
    <div style={{ display: 'flex', padding: '10px 20px' }}>
      <div style={{ color: '#CB8B65', textTransform: 'uppercase', fontWeight: 'bold' }}>{item.postCategory}</div>&nbsp; /
      <div>&nbsp;{item.postDate}</div>
    </div>
    <div style={{ fontSize: '24px', lineHeight: '2rem', padding: '0px 20px', letterSpacing: '0.2px', wordSpacing: '-2px', fontWeight: '500', height: '96px' }}>
      <Link style={{textDecoration:'none',color:'black'}} to={`/blog/${item.postTitle.toLowerCase().replaceAll(" ", "-")}`} state={item}>
        <div style={{ height: '80px' }}>
          {item.postTitle.length > 50 ? item.postTitle.substring(0, 56) + '...' : item.postTitle}
        </div>
        <div style={{ padding: '0px 0px', color: '#CB8B65', fontWeight: '500', fontSize:'15px' }}>Read More...</div>
      </Link>
    </div>
  </div>
)) : <div></div>}

      </div>

      {blogBox.length >= 3 ? blogBox.slice(0, 1).map((item, index) => (
      <a href="/blogs" style={{ textDecoration: 'none', }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <div style={{ textAlign: 'center', fontSize: '20px', backgroundColor: '#CB8B65', padding: '10px 15px', borderRadius: '10px', fontWeight: 'bold', cursor: 'pointer', color: 'white' }}>View More</div>
        </div>
      </a>
        )) :''}




      {/* Our Service section */}
      <div style={{ padding: isXs ? '40px 50px' : '0px 30px', display: 'flex', flexDirection: 'column', gap: '12px' }}>

        <div style={{ textAlign: 'center', fontSize: '28px', fontWeight: 'bold', marginBottom: isXs ? '40px' : '20px' }}>Home Tutoring - in your city </div>

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Home Tutions in Jaipur Personalized Coaching at Your Doorstep:</span> </div>
        <div style={{ fontSize: '16px' }}>Our home tutions in Jaipur provide personalized coaching to students at their doorstep. Our experienced and qualified tutors offer one-on-one coaching, ensuring that students receive individualized attention and guidance to achieve their academic goals. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Online Classes in Jaipur Specialized IIT JEE Coaching with (One to One) Attention:</span> </div>
        <div style={{ fontSize: '16px' }}>Our online classes in Jaipur provide specialized coaching for IIT JEE, with a focus on personalized attention and guidance for each student. Our experienced and qualified tutors offer (One to One) online coaching, ensuring that students receive individualized support and guidance to achieve their academic goals.</div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Home Tutor in Delhi:</span> </div>
        <div style={{ fontSize: '16px' }}>Experienced and qualified home tutors in Delhi for all subjects and classes, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our home tutors provide personalized attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Home Tution in Delhi:</span> </div>
        <div style={{ fontSize: '16px' }}>Experienced and qualified home tuition in Delhi for all subjects and classes, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our home tuition provides personalized attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Online Tutor (One to One) in Delhi:</span> </div>
        <div style={{ fontSize: '16px' }}>Flexible and convenient online tuition in Delhi for students of all ages and subjects, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our online tutors provide one-on-one attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Home Tutor in Mumbai:</span> </div>
        <div style={{ fontSize: '16px' }}>Experienced and qualified home tutors in Mumbai for all subjects and classes, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our home tutors provide personalized attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}> Home Tution in Mumbai:</span> </div>
        <div style={{ fontSize: '16px' }}>Experienced and qualified home tuition in Mumbai for all subjects and classes, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our home tuition provides personalized attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Online Tutor (One to One) in Mumbai:</span> </div>
        <div style={{ fontSize: '16px' }}>Flexible and convenient online tuition in Mumbai for students of all ages and subjects, including IIT ,JEE, NEET, 12th, 11th, and up to 10th. Our online tutors provide one-on-one attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Home Tutor in Bengaluru:</span> </div>
        <div style={{ fontSize: '16px' }}>Experienced and qualified home tutors in Bengaluru for all subjects and classes, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our home tutors provide personalized attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Home Tution in Bengaluru:</span> </div>
        <div style={{ fontSize: '16px' }}>Experienced and qualified home tuition in Bengaluru for all subjects and classes, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our home tuition provides personalized attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Online Tutor (One to One) in Bengaluru:</span> </div>
        <div style={{ fontSize: '16px' }}>Flexible and convenient online tuition in Bengaluru for students of all ages and subjects, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our online tutors provide one-on-one attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Home Tutor in Gurgaon:</span> </div>
        <div style={{ fontSize: '16px' }}>Experienced and qualified home tutors in Gurgaon for all subjects and classes, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our home tutors provide personalized attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Home Tution in Gurgaon:</span> </div>
        <div style={{ fontSize: '16px' }}>Experienced and qualified home tuition in Gurgaon for all subjects and classes, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our home tuition provides personalized attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}> Online Tutor (One to One) in Gurgaon </span> </div>
        <div style={{ fontSize: '16px' }}>Flexible and convenient online tuition in Gurgaon for students of all ages and subjects, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our online tutors provide one-on-one attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects.  </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Online Classes in Chennai:</span> </div>
        <div style={{ fontSize: '16px' }}><span style={{ fontSize: '16px', fontWeight: '500' }}> Specialized IIT JEE Coaching with (One to One) Attention:</span> Our online classes in Chennai provide specialized coaching for IIT JEE, with a focus on personalized attention and guidance for each student. Our experienced and qualified tutors offer (One to One) online coaching, ensuring that students receive individualized support and guidance to achieve their academic goals. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Home Tutions in Chennai:</span> </div>
        <div style={{ fontSize: '16px' }}><span style={{ fontSize: '16px', fontWeight: '500' }}> Personalized Coaching at Your Doorstep:</span> Our home tutions in Chennai provide personalized coaching to students at their doorstep. Our experienced and qualified tutors offer one-on-one coaching, ensuring that students receive individualized attention and guidance to achieve their academic goals. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />


        <div style={{ textAlign: 'center', fontSize: '28px', fontWeight: 'bold', marginBottom: isXs ? '40px' : '20px', marginTop: isXs ? '40px' : '20px' }}>Why Choose Kanha Home Tuition?</div>
        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Home Tution:</span> </div>
        <div style={{ fontSize: '16px' }}>Experienced and qualified home tutors for all subjects and classes, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our home tutors provide personalized attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects to help students achieve their academic goals.</div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Best Home Tutors:</span> </div>
        <div style={{ fontSize: '16px' }}>Our team of expert home tutors are highly qualified and experienced in their respective subjects. </div>
        <ul style={{ display: 'flex', flexDirection: 'column', gap: '5px', paddingLeft: '0px' }}>
          <li style={{ fontSize: '16px', listStyle: "none" }}><span style={{ fontSize: '16px', fontWeight: '500' }}>1. Personalized Attention:</span> We provide one-on-one attention to each student, ensuring they receive the best possible guidance and support. </li>
          <li style={{ fontSize: '16px', listStyle: "none" }}><span style={{ fontSize: '16px', fontWeight: '500' }}>2. Flexible Scheduling:</span> We understand that every student's schedule is different, which is why we offer flexible scheduling to fit your needs. </li>
          <li style={{ fontSize: '16px', listStyle: "none" }}><span style={{ fontSize: '16px', fontWeight: '500' }}>3. Proven Track Records:</span> Our home tutors have a proven track record of success, with many students achieving top grades and securing admission to top universities.</li>
        </ul>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Online Tution ( One to One ):</span> </div>
        <div style={{ fontSize: '16px' }}>Flexible and convenient online tuition for students of all ages and subjects, including IIT JEE, NEET, 12th, 11th, and up to 10th. Our online tutors provide one-on-one attention and guidance in Mathematics, Physics, Chemistry, Biology, and all other subjects to help students learn at their own pace. </div>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Online Classes:</span> </div>
        <div style={{ fontSize: '16px' }}>IIT- JEE, NEET, NDA (One to One) Attention  </div>
        <div style={{ fontSize: '16px' }}>Our online classes provide specialized coaching for, IIT- JEE, NEET, NDA. with a focus on personalized attention and guidance for each student. Our experienced and qualified tutors offer online coaching, IIT- JEE, NEET, NDA ensuring that students receive individualized support and guidance to achieve their academic goals. </div>
        <ul style={{ display: 'flex', flexDirection: 'column', gap: '5px', }}>
          <li style={{ fontSize: '16px' }}>( One to One ) online coaching for IIT- JEE,NEET,NDA </li>
          <li style={{ fontSize: '16px' }}>Experienced and qualified tutors</li>
          <li style={{ fontSize: '16px' }}>Personalized attention and guidance</li>
          <li style={{ fontSize: '16px' }}>Flexible and convenient online classes</li>
          <li style={{ fontSize: '16px' }}>Comprehensive study material and resources</li>
        </ul>
        <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />





        <div style={{ textAlign: 'center', fontSize: '28px', fontWeight: 'bold', marginBottom: isXs ? '40px' : '20px', marginTop: isXs ? '40px' : '20px' }}>Subjects and Levels We Cover</div>
        <div style={{ display: 'flex', gap: '3px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Maths Tutors:</span></div>
          <div style={{ fontSize: '16px' }}> Our experienced maths tutors provide expert guidance and support to help student’s master mathematical concepts. </div>
          <hr style={{ marginTop: '10px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

          <div></div>
        </div>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Physics Tutors:</span></div>
          <div style={{ fontSize: '16px' }}> Our physics tutors are highly qualified and experienced in helping students understand complex physics concepts.</div>
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />
        </div>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Chemistry Tutors:</span></div>
          <div style={{ fontSize: '16px' }}> Our chemistry tutors provide expert guidance and support to help students understand chemical reactions and processes.</div>
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        </div>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Biology Tutors:</span> </div>
          <div style={{ fontSize: '16px' }}> Our biology tutors are highly qualified and experienced in helping students understand biological concepts and processes. </div>
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        </div>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>IIT JEE Tutors (Mains and Advanced): </span> </div>
          <div style={{ fontSize: '16px' }}> Our experienced IIT JEE tutors provide expert guidance and support to help students prepare for IIT JEE exams. </div>
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        </div>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>NEET Tutors:</span> </div>
          <div style={{ fontSize: '16px' }}>Our NEET tutors are highly qualified and experienced in helping students prepare for NEET exams. </div>
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        </div>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>NDA Tutors:</span> </div>
          <div style={{ fontSize: '16px' }}>Our NDA tutors provide expert guidance and support to help students prepare for NDA exams.</div>
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        </div>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Commerce Tutors</span> </div>
          <div style={{ fontSize: '16px' }}> Our commerce tutors cover all subjects, including Accountancy, Business Studies, Economics, and more.</div>
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        </div>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Arts Tutors:</span> </div>
          <div style={{ fontSize: '16px' }}>Our arts tutors cover all subjects, including History, Geography, Political Science, and more. </div>
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        </div>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>12th Tutors:</span> </div>
          <div style={{ fontSize: '16px' }}>Our 12th tutors cover all subjects, including math, science, commerce, and arts.</div>
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        </div>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}> 11th Tutors:</span> </div>
          <div style={{ fontSize: '16px' }}>Our 11th tutors cover all subjects, including math, science, commerce, and arts. </div>
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />

        </div>
        <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>CBSE Tutors:</span> </div>
          <div style={{ fontSize: '16px' }}>Our CBSE tutors are highly qualified and experienced in helping students prepare for CBSE exams. </div>
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Online Tutions and Tutors:</span> </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <div style={{ fontSize: '16px' }}>1. We also provide online tutions and tutors for all subjects and levels, ensuring that students can learn from the comfort of their own homes. </div>
            <div style={{ fontSize: '16px' }}>2. Our online tutors are highly qualified and experienced, and use the latest technology to provide interactive and engaging lessons.</div>
          </div>
          <hr style={{ marginTop: '10px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
          <div style={{ fontSize: '16px' }}> <span style={{ fontSize: '18px', fontWeight: '500' }}>Services We Provide:</span> </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <div style={{ fontSize: '16px' }}><span style={{ fontSize: '16px', fontWeight: '500' }}>1. Home Tutions:</span> We provide home tutions for all subjects and levels, ensuring that students receive personalized attention and guidance.</div>
            <div style={{ fontSize: '16px' }}><span style={{ fontSize: '16px', fontWeight: '500' }}>2. Online Tutions:</span> We provide online tutions for all subjects and levels, ensuring that students can learn from the comfort of their own homes. </div>
            <div style={{ fontSize: '16px' }}><span style={{ fontSize: '16px', fontWeight: '500' }}>3. Group Tutions:</span> We provide group tutions for students who prefer to learn in a group environment.</div>
            <div style={{ fontSize: '16px' }}><span style={{ fontSize: '16px', fontWeight: '500' }}>4. Test Preparation:</span> We provide test preparation services for students who are preparing for competitive exams.</div>

          </div>
          <hr style={{ marginTop: '10px', marginBottom: '5px', border: '0', borderTop: '1px solid rgb(96, 94, 94)' }} />
        </div>
      </div>



      <div style={{ marginTop: "80px ", marginBottom: isXs ? "" : "100px" }}>
        <div style={{ position: "relative" }}>
          <div
            style={{
              textAlign: "center",
              backgroundColor: isXs ? "" : "#C9875F",
              height: isXs ? "" : "730px",
              borderBottomLeftRadius: "50px",
              borderTopRightRadius: "50px",
              display: "flex",
              justifyContent: "center",
              margin: isXs ? "" : "0px 5%",
            }}
          >
            <img
              style={{ width: isXs ? "80%" : "0%" }}
              src={QuicklinkBg}
              alt="QuicklinkBg"
            />
            <div
              style={{
                position: "absolute",
                top: "32px",
                fontSize: "28px",
                fontWeight: "bold",
                left: isXs ? "44.5%" : "",
                color: "white",
                display: "flex",
                flexDirection: "column",
                textAlign: isXs ? "" : "center",
              }}
            >
              Quick Links{" "}
              <img style={{ width: "70px" }} src={Linenew} alt="LineNEW" />
            </div>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div style={styles.grid}>
                {quickLinks.slice(0, 6).map((boxText, index) => (
                  <div
                    onClick={() =>
                      toggleSidebar(boxText.quick_link_name, boxText.seo_pages)
                    }
                    key={index}
                    style={styles.box}
                  >
                    {boxText.quick_link_name}
                    <img
                      src={ArrowNew}
                      style={{ height: "30px", marginLeft: "10px" }}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Sidebar */}
        <div style={{ position: "absolute", zIndex: "1000" }}>
          <div
            ref={sidebarRef}
            style={{
              position: "fixed",
              top: 40,
              right: 0,
              height: isXs ? "530px" : "750px",
              width: isXs ? "390px" : "340px",
              backgroundColor: "#efe8e8",
              color: "black",
              transform: isOpen ? "translateX(00)" : "translateX(100%)",
              transition: "transform 0.3s",
              boxShadow: "1px 2px 2px rgb(10, 10, 10 ,0.2)",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0px",
                backgroundColor: "#A6653F",
                color: "white",
                padding: "10px 10px",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "18px",
                  paddingRight: "16px",
                  top: "0",
                  position: "sticky",
                  zIndex: "1000",
                }}
              >
                {/* Add this button text */}
                {selectedText}
              </div>
              {isOpen && (
                <div
                  onClick={() => toggleSidebar()}
                  style={{ cursor: "pointer", color: "white" }}
                >
                  {/* <CloseIcon style={{ backgroundColor: '#f7dc99', color: 'black', borderRadius: '50%', position: 'absolute', right: '-2', top: '0' }} /> */}
                </div>
              )}{" "}
            </div>
            <div
              style={{
                overflowY: "auto",
                maxHeight: isXs ? "480px" : "700px",
                paddingBottom: "10px",
                paddingTop: "10px",
              }}
            >
              <div style={{ paddingLeft: "10px" }}>
                {seoPages.map((seoPage, index) => (
                  <Link
                    to={`/${seoPage.txt_slug
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                    key={index}
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontSize: "16px",
                    }}
                  >
                    <li
                      style={{
                        textIndent: "-1.5rem",
                        paddingLeft: "2rem ",
                        marginBottom: "15px",
                      }}
                      className="listiem"
                    >
                      {seoPage.txt_meta_title}
                    </li>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section7 Web */}
      <Grid
        container
        sx={{
          display: isXs ? "flex" : "none",
          marginTop: "5%",
          display: isXs ? "" : "none",
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div
            className="d-flex justify-content-center"
            style={{
              position: "relative",
              marginTop: "10%",
              marginBottom: "10%",
            }}
          >
            <img
              src={Frame25}
              alt="Frame24"
              style={{
                position: "absolute",
                width: "78%",
                height: "auto",
                zIndex: 2,
                left: "8.5rem",
                top: "-3rem",
              }}
            />
            <div
              className="d-flex align-items-center"
              style={{
                borderBottomRightRadius: "10px",
                height: "20rem",
                width: "80%",
                backgroundColor: "#F6EBDC",
                borderTopRightRadius: "150px",
                borderBottomLeftRadius: "150px",
              }}
            >
              <div
                style={{
                  maxWidth: "40%",
                  marginLeft: "6vw",
                  marginBottom: "5%",
                }}
              >
                <div style={{ fontSize: "1.5vw", width: "90%" }}>
                  Download the Kanha Home Tuitions Student and Tutor apps now on
                  Google Play Store!
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* Section7 Mobile */}
      <Grid
        container
        sx={{
          display: isXs ? "none" : "",
          marginTop: "5%",
          display: isXs ? "none" : "",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <img
            src={GroupPhone}
            alt="GroupPhone"
            style={{
              position: "absolute",
              width: "50%",
              height: "auto",
              zIndex: 1,
            }}
          />
          <img
            src={PhoneDoodlePlus}
            style={{
              position: "absolute",
              zIndex: 2,
              left: "3rem",
              top: "7rem",
            }}
          />
          <img
            src={PhoneDoodleMultiply}
            style={{
              position: "absolute",
              zIndex: 2,
              right: "3rem",
              top: "8rem",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div
            className="d-flex justify-content-center"
            style={{
              position: "relative",
              marginTop: "20%",
              marginBottom: "10%",
            }}
          >
            <div
              className="d-flex align-items-center"
              style={{
                borderBottomRightRadius: "10px",
                height: "25rem",
                width: "90%",

                backgroundColor: "#F6EBDC",
                borderTopRightRadius: "50px",
                borderBottomLeftRadius: "50px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  minWidth: "100%",
                  marginTop: "45%",
                  marginBottom: "5%",
                }}
              >
                <div
                  style={{
                    fontSize: "4.2vw",
                    width: "80%",
                    margin: "0 auto",
                    marginTop: "2%",
                    textAlign: "center",
                    marginBottom: "15%",
                  }}
                >
                  Download the Kanha Home Tuitions Student and Tutor apps now on
                  Google Play Store!
                </div>
                <Grid
                  item
                  xs={10}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  xxl={3}
                  container
                  spacing={3}
                  sx={{ margin: "0 auto" }}
                >

                </Grid>
                <img
                  src={PhoneDoodleBook}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    left: "4rem",
                    bottom: "2rem",
                  }}
                />
                <img
                  src={PhoneDoodleDivide}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    right: "3rem",
                    bottom: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <Box>
        <Button
          onClick={() => {
            navigate("/accountstudent");
          }}
          sx={{ color: "white" }}
        >
          abc
        </Button>
        <Button
          onClick={() => {
            navigate("/accountteacher");
          }}
          sx={{ color: "white" }}
        >
          xyz
        </Button>
      </Box>

      <Footer />
    </>
  );
};

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    background-color: white;
    border-radius: 14px;
  }
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export default Landing;
